import "./App.css";
import LoginPage from "./Screens/Login.js";
import CorporateLoginPage from "./Screens/CorporateLogin.js";
import RegisterPage from "./Screens/Register.js";
import CorporateRegisterPage from "./Screens/CorporateRegister.js";
import IndexPage from "./Screens/Index";
import PinPage from "./Screens/Pin";
import DashboardPage from "./Screens/Dashboard";
import WithdrawPage from "./Screens/Withdraw";
import DepositPage from "./Screens/Deposit";
import AcCard from "./Screens/AcCard";
import Transfer from "./Screens/Transfer";
import AddCard from "./Screens/AddCard";
import AddBankAccount from "./Screens/Addbankaccount";
import TransactionPage from "./Screens/Transaction";
import ForgetPassword from "./Screens/ForgetPassword";
import KYCPage from "./Screens/KYC";
import ServicesPage from "./Screens/Services";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

function App() {
  document.title = "AltinCuzdan | Altın Cüzdan";
  const googleTranslate = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate"
    );
  };
  React.useEffect(() => {
    if (!sessionStorage.getItem("lang")) {
      sessionStorage.setItem("lang", "en");
    }

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslate;
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route index element={<IndexPage />} />
        <Route path="home" element={<IndexPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="corporateLogin" element={<CorporateLoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="corporateRegister" element={<CorporateRegisterPage />} />
        <Route path="pin" element={<PinPage />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="withdraw" element={<WithdrawPage />} />
        <Route path="deposit" element={<DepositPage />} />
        <Route path="ac-card" element={<AcCard />} />
        <Route path="transfer" element={<Transfer />} />
        <Route path="add-card" element={<AddCard />} />
        <Route path="add-bank-account" element={<AddBankAccount />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="transaction-page" element={<TransactionPage />} />
        <Route path="kyc" element={<KYCPage />} />
        <Route path="services" element={<ServicesPage />} />
      </Route>
    )
  );

  ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  );
}

export default App;
