import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import logo from "../assets/img/altinCuzdanLogo.png";
import rightPhoto from "../assets/img/securtiyPhoto.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { lightColors, darkColors } from "../Colors/Color";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Components/Footer";
import axios from "axios";

const LoginPage = ({ navigation }) => {
  document.body.style.backgroundColor = "white";
  let navigate = useNavigate();

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [buttonPosition, setButtonPosition] = React.useState("center");
  const [text, setText] = React.useState("");

  const emailChange = (event) => {
    setEmail(event.target.value);
    setText("");
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
    setText("");
  };

  function checkFunction() {
    if (email.length > 0 && password.length > 0) {
      setText("");
    } else {
      setText(langObject.warningBlanks);
      if (buttonPosition == "center") {
        setButtonPosition("left");
      } else {
        setButtonPosition("center");
      }
    }
  }

  const redirectToPin = () => {
    navigate("/pin", { state: { account_type: 2 } });
  };

  function girisYap() {
    let gonderilecekVeriler = {
      email: email,
      password: password,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/CorporateLogin", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          sessionStorage.setItem("token", sonuc.data.data.token);
          sessionStorage.setItem("account_type", 2);
          sessionStorage.setItem("fake_user_id", sonuc.data.data.id);
          sessionStorage.setItem("avatar", sonuc.data.data.avatar);
          redirectToPin();
        } else {
          toast.error(sonuc.data.alert);
        }
      });
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: colors.backgroundColor }}
    >
      <div className="container p-0 pt-5" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col-md-8">
            <img
              src={rightPhoto}
              className="d-flex"
              style={styles.securityPhoto}
            />
          </div>
          <div className="col-md-4 mt-5">
            <a href="home" className="d-block" style={{ textAlign: "center" }}>
              <img src={logo} width="auto" height="90px" className="mb-4" />
            </a>
            <h1>
              <FontAwesomeIcon icon="fa-solid fa-house" />
              {langObject.corporateLogin}
            </h1>
            <p className="fs-6 mt-4 mb-4">{langObject.individualAccountText}</p>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                onChange={emailChange}
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label for="floatingInput">
                E-mail <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                onChange={passwordChange}
                placeholder="••••••••••"
                style={{ height: "58px" }}
              />

              {buttonPosition == "center" ? (
                <div className="gap-2 text-left">
                  <input
                    type="button"
                    onMouseEnter={checkFunction}
                    className="btn mt-2"
                    onClick={girisYap}
                    value={langObject.signIn}
                    style={{
                      height: "58px",
                      width: "40%",
                      color: "#F0B90B",
                      backgroundColor: "white",
                      border: "1px solid #F0B90B",
                    }}
                  />
                </div>
              ) : (
                <div className="gap-2" style={{ textAlign: "right" }}>
                  <input
                    type="button"
                    onMouseEnter={checkFunction}
                    className="btn mt-2"
                    value={langObject.signIn}
                    onClick={girisYap}
                    style={{
                      height: "58px",
                      width: "40%",
                      color: "#F0B90B",
                      backgroundColor: "white",
                      border: "1px solid #F0B90B",
                    }}
                  />
                </div>
              )}
              <p className="text-danger">{text}</p>
              <hr className="mt-3" />

              <p className="text-center">
                {langObject.haveAnAccount}
                <a
                  href="corporateRegister"
                  style={{
                    textDecoration: "none",
                    color: "#F0B90B",
                    marginLeft: "10px",
                  }}
                >
                  {langObject.signUp}
                </a>
              </p>
              <p className="text-center">
                <a
                  href="forget-password"
                  style={{
                    textDecoration: "none",
                    color: "#F0B90B",
                    marginLeft: "10px",
                  }}
                >
                  {langObject.forgetPassword}
                </a>
              </p>
              <p className="text-center">
                <a
                  href="login"
                  style={{
                    textDecoration: "none",
                    color: "#F0B90B",
                    marginLeft: "10px",
                  }}
                >
                  {langObject.individualAccount}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ position: "absolute", bottom: "0" }} />
      <ToastContainer />
    </div>
  );
};

const styles = {
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "black",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  securityPhoto: {
    margin: "auto",
    width: "80%",
    height: "auto",
  },
  changeA: {
    textDecoration: "none",
    color: "black",
  },
};

export default LoginPage;
