import React, { useEffect } from "react";
import "../main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { lightColors, darkColors } from "../Colors/Color";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  NavLink,
  Row,
} from "react-bootstrap";
import logo from "../assets/img/altinCuzdanLogo.png";
import englishLogo from "../assets/img/englishLanguage.png";
import usdLogo from "../assets/img/usd.svg";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import europeLogo from "../assets/img/Flag_of_Europe.svg";
import investment from "../assets/img/investment.jpg";
import earnings from "../assets/img/earnings.png";
import { IoIosPerson } from "react-icons/io";
import { MdNotifications, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import { FaUserShield, FaChartPie, FaHistory } from "react-icons/fa";
import { BsCreditCard2BackFill, BsBank2 } from "react-icons/bs";
import { FiDivideCircle } from "react-icons/fi";
import { GiShop } from "react-icons/gi";
import { AiFillSetting } from "react-icons/ai";
import { SlEarphonesAlt } from "react-icons/sl";
import { ImExit } from "react-icons/im";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Menu = (navigation) => {
  const [div1, setDiv1] = React.useState("none"); //Account open
  const [div2, setDiv2] = React.useState("none"); //Wallet open
  const [user_id, setUserId] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [walletData, setWalletData] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [assetList, setAssetList] = React.useState("");
  const [accountType, setaccountType] = React.useState("");

  async function getData() {
    let result = await sessionStorage.getItem("user_id");
    let result2 = await sessionStorage.getItem("account_type");
    if (result) {
      setUserId(result);
      setaccountType(result2);
    }
  }

  let navigate = useNavigate();

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const colorStyles = getStyles(colors);

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);
  useEffect(() => {
    getWallet("TRY");
    getUserInfo();
    getAssetList();
    getKYCInfo();
  }, [user_id]);

  const redirectToKYC = () => {
    navigate("/KYC", { state: { account_type: 1 } });
  };

  async function getUserInfo() {
    let gonderilecekVeriler = {
      user_id: user_id,
      account_type: accountType,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  async function getKYCInfo() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
    };

    await axios
      .post("https://acdigitalapi.com/getKYCStatus", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (
          sonuc.data.message == "KYC_not_found" ||
          sonuc.data.message == "KYC_pending"
        ) {
          //redirectToKYC();
        }
      });
  }

  function getAssetList() {
    axios
      .post("https://acdigitalapi.com/assetList", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: user_id,
      account: asset,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"), // Her yere koyulacak --> tüm axioslara koyulacak
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }
  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                    alt="logo"
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }

  function logoutFunction() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_id");
    window.location.href = "/";
  }

  function accountOpen() {
    if (div1 === "block") {
      setDiv1("none");
    } else {
      setDiv1("block");
    }
  }

  function walletOpen() {
    if (div1 === "block") {
      setDiv1("none");
    } else {
      setDiv1("block");
    }
  }

  function changeTheme() {
    let theme = sessionStorage.getItem("theme");

    if (theme === "dark") {
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "light") {
      sessionStorage.setItem("theme", "dark");
    }
  }
  return (
    <div>
      <div
        className="navbar navbar-expand-lg logFix"
        style={{ background: "#101010" }}
      >
        <div
          className="container-fluid d-inline"
          style={{ position: "relative" }}
        >
          <div
            className="row changeRow"
            style={{
              alignItems: "center",
              width: "auto",
            }}
          >
            <div className="col-lg-2" id="LogoDiv">
              <a href="dashboard">
                <img src={logo} width="auto" height="70px" id="MenuLogo" />
              </a>
            </div>
            <div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ background: colors.goldColor }}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              class="collapse navbar-collapse"
              id="navbarSupportedContent"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="col-lg-5 fontSize menuWidth2"
                style={{ width: "60%" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <a
                      href="dashboard"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.Dashboard}
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a
                      href="transfer"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.Transfer}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="deposit"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.Deposit}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="withdraw"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.Withdraw}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="ac-card"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.AcCard}
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="col-lg-5 fontSize menuRightWidth2"
                style={{ width: "60%" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      href="deposit"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      <BsBank2 style={styles.iconSizing} />{" "}
                      {langObject.BankAccount}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href="transaction-page"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      <FaHistory style={styles.iconSizing} />
                      {langObject.Transaction}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href="javascrpt:logoutFunction()"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      <ImExit style={styles.iconSizing} />
                      {langObject.Logout}
                    </a>
                  </li>
                </ul>
              </div>

              <div
                className="col-lg-2 accountOpen"
                style={{
                  cursor: "pointer",
                  width: "40%",
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={accountOpen}
              >
                <div
                  className="row"
                  style={{
                    height: "70px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div className="col-8 px-0 pt-3">
                    <div className="row" style={{ flexDirection: "column" }}>
                      <div
                        className="col"
                        style={{ textAlign: "right", fontWeight: "600" }}
                      >
                        <span className="text-light" style={styles.menuNavLink}>
                          {langObject.Welcome}
                          {accountType == 1
                            ? userData.only_name
                            : userData.company_name}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col px-0"
                      style={
                        (styles.menuNavLink,
                        { textAlign: "right", fontSize: "0.8rem" })
                      }
                    >
                      <span style={styles.menuNavLink}>
                        {langObject.MyAccount}
                      </span>
                    </div>
                  </div>

                  <div className="col-3 d-flex justify-content-center align-items-center p-0">
                    <img
                      src={sessionStorage.getItem("avatar")}
                      style={{
                        width: "40px",
                        borderRadius: "100%",
                        margin: "0 10px",
                      }}
                    />
                  </div>

                  <div
                    className="col-1 d-flex justify-content-center align-items-center p-0"
                    style={{ width: "10%", marginLeft: "-10px" }}
                  >
                    <MdOutlineKeyboardArrowDown
                      color={"#ffffff"}
                      size={"40px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="clickMenu"
          className="text-light"
          style={{
            display: div1,
            width: "425px",
            minHeight: "100%",
            position: "absolute",
            right: "0",
            top: "86px",
            overflowY: "scroll",
            height: "100vh",
            zIndex: "999",
            background: "#101010",
          }}
        >
          <ul id="clickMenuScroll" className="navbar-nav ">
            <li className="nav-item ">
              <a href="deposit" className="nav-link" style={styles.menuNavLink}>
                <div className="row">
                  <div className="col-1 iconChange">
                    <BsBank2 className="iconSizingClick" />
                  </div>
                  <div className="col">
                    <span className="rightClickTitle">
                      {langObject.BankAccount}
                      <br />
                    </span>
                    <span className="rightClickText">
                      {langObject.addRemove}
                    </span>
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item ">
              <a
                href="transaction-page"
                className="nav-link"
                style={styles.menuNavLink}
              >
                <div className="row">
                  <div className="col-1 iconChange">
                    <FaHistory className="iconSizingClick" />
                  </div>
                  <div className="col">
                    <span className="rightClickTitle">
                      {langObject.Transaction} <br />
                    </span>
                    <span className="rightClickText">
                      {langObject.transactionText}
                    </span>
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item ">
              <a
                onClick={() => logoutFunction()}
                href="#"
                className="nav-link"
                style={styles.menuNavLink}
              >
                <div className="row">
                  <div className="col-1 iconChange">
                    <ImExit className="iconSizingClick" />
                  </div>
                  <div className="col">
                    <span className="rightClickTitle">
                      {langObject.Logout} <br />
                    </span>
                    <span className="rightClickText"></span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const styles = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

const getStyles = (colors) => ({});
export default Menu;
