import React, { useState, useEffect } from "react";
import LoginMenu from "../Components/LoginMenu";
import Footer from "../Components/Footer";
import { lightColors, darkColors } from "../Colors/Color";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Front from "../assets/img/Front.png";
import Back from "../assets/img/Back.png";
import Selfie from "../assets/img/Selfie.jpg";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import axios from "axios";
import { AiOutlineRight } from "react-icons/ai";
import Moment from "react-moment";
import "moment/locale/tr";
import LoginPage from "./Login";
import swapImage from "../assets/img/swap.png";
import "../assets/css/onlyDashboard.css";

import { Navigate, redirect } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";

const UserIndexPage = () => {
  document.body.style.backgroundColor = "#101010";

  const [theme, setTheme] = React.useState("");
  const [user_id, setUserId] = React.useState(null);
  const [frameUrl, setFrmaeUrl] = React.useState(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const [userData, setUserData] = React.useState("");
  const [assetList, setAssetList] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [ExchangeAsset1, setExchangeAsset1] = React.useState("TRY");
  const [ExchangeAsset2, setExchangeAsset2] = React.useState("EUR");
  const [walletData, setWalletData] = React.useState("");
  const [ExchangeData1, setExchangeData1] = React.useState("");
  const [ExchangeData2, setExchangeData2] = React.useState("");
  const [kycStatusFront, setKycStatusFront] = React.useState("");
  const [kycStatusBack, setKycStatusBack] = React.useState("");
  const [kycStatusSelfie, setKycStatusSelfie] = React.useState("");

  const [ModalVisible, setModalVisible] = React.useState(false);

  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [lastOperations, setLastOperations] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [asset, setAsset] = React.useState("6367c3e400ac884500039085");
  const [asset2, setAsset2] = React.useState("6367c3af00ac88450003907f");

  const [exchangeMiktar, setExchangeMiktar] = React.useState(0);
  const [account_type, setAccountType] = React.useState(null);
  const [isReady, setisReady] = React.useState(false);
  const [isRendered, setisRendered] = React.useState(false);
  async function getData() {
    let result = sessionStorage.getItem("user_id");

    if (result) {
      setUserId(result);
    }
  }

  const notify = () => toast("Wow so easy!");

  useEffect(() => {
    setAccountType(sessionStorage.getItem("account_type"));

    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    getKYCInfo();
  }, []);

  async function getKYCInfo() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
    };

    await axios
      .post("https://acdigitalapi.com/getKYCStatus", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.message == "KYC_not_found") {
          setKycStatusFront("not_found");
          setKycStatusBack("not_found");
          setKycStatusSelfie("not_found");
        }
      });
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const styles = getStyles(colors);
  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: colors.goldColor,
          height: "100%",
          width: "100%",
          padding: "0",
          overflow: "hidden",
        }}
      >
        <LoginMenu />
        <div
          className="container-fluid"
          style={{
            borderTop: "1px solid rgb(58, 58, 58)",
            background: "#101010",
          }}
        >
          <div className="row">
            <div
              className="col-lg-4 scrollChange scrollHeight"
              style={{
                borderRight: "1px solid rgb(58, 58, 58)",
                height: "95vh",
                overflowY: "scroll",
                padding: "0px 20px 10px 20px",
              }}
            >
              {kycStatusFront == "not_found" ? (
                <div
                  className="row text-light"
                  style={{ flexDirection: "column" }}
                >
                  <div className="col">
                    <h1
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        color: colors.goldColor,
                      }}
                    >
                      Front
                    </h1>
                    <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                      Have a valid ID ready
                    </h3>
                  </div>
                  <div className="col">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={Front}
                        width="90%"
                        height={"50%"}
                        style={{ borderRadius: "15px", marginTop: "10px" }}
                      ></img>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <form action="/action_page.php">
                        <input type="file" id="myFile" name="filename" />
                      </form>
                    </div>
                    <button
                      className="btn btn-warning btn-block w-100 mt-3"
                      type="submit"
                    >
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p>asd1</p>
                </div>
              )}
            </div>
            <div
              className="col-lg-4 scrollChange scrollHeight"
              style={{
                borderRight: "1px solid rgb(58, 58, 58)",
                height: "95vh",
                overflowY: "scroll",
                padding: "0px 20px 10px 20px",
              }}
            >
              {kycStatusBack == "not_found" ? (
                <div
                  className="row text-light"
                  style={{ flexDirection: "column" }}
                >
                  <div className="col">
                    <h1
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        color: colors.goldColor,
                      }}
                    >
                      Back
                    </h1>
                    <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                      Both sides please
                    </h3>
                  </div>
                  <div className="col">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={Back}
                        width="90%"
                        height={"50%"}
                        style={{ borderRadius: "15px", marginTop: "10px" }}
                      ></img>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <form>
                        <input type="file" />
                      </form>
                    </div>
                    <button
                      className="btn btn-warning btn-block w-100 mt-3"
                      type="submit"
                    >
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p>asd1</p>
                </div>
              )}
            </div>
            <div
              className="col-lg-4 scrollChange scrollHeight"
              style={{
                borderRight: "1px solid rgb(58, 58, 58)",
                height: "95vh",
                overflowY: "scroll",
                padding: "0px 20px 10px 20px",
              }}
            >
              {kycStatusSelfie == "not_found" ? (
                <div
                  className="row text-light"
                  style={{ flexDirection: "column" }}
                >
                  <div className="col">
                    <h1
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        color: colors.goldColor,
                      }}
                    >
                      Selfie
                    </h1>
                    <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                      Good lighting is essential
                    </h3>
                  </div>
                  <div className="col">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={Selfie}
                        width="90%"
                        style={{ borderRadius: "15px", marginTop: "10px" }}
                      ></img>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mt-3">
                      <form>
                        <input type="file" id="myfile" name="myfile" />
                      </form>
                    </div>
                    <button
                      className="btn btn-warning btn-block w-100 mt-3"
                      type="submit"
                    >
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p>asd1</p>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

const styles2 = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

const getStyles = (colors) => ({});

export default UserIndexPage;
