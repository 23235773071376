import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import logo from "../assets/img/altinCuzdanLogo.png";
import rightPhoto from "../assets/img/securtiyPhoto.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { lightColors, darkColors } from "../Colors/Color";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Components/Footer";
import axios from "axios";

const CorporateRegisterPage = ({ navigation }) => {
  document.body.style.backgroundColor = "white";
  document.body.style.overflowY = "auto";

  let navigate = useNavigate();

  const redirectToPin = () => {
    navigate("/corporateLogin");
  };

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const [text, setText] = React.useState("");
  const [isim, setIsim] = React.useState("");
  const [soyisim, setSoyisim] = React.useState("");
  const [Mail, setMail] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [Telefon, setTelefon] = React.useState("");
  const [password1, setsifre] = React.useState("");
  const [password2, setsifre2] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [birth_place, setBirthplace] = React.useState("");
  const [sehir, setSehir] = React.useState("");
  const [adres, setAdres] = React.useState("");
  const [kimlikno, setKimlikno] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [company_name, setCompanyname] = React.useState("");
  const [company_tax_number, setCompanytaxnumber] = React.useState("");
  const [company_address, setCompanyaddress] = React.useState("");
  const [company_city, setCompanycity] = React.useState("");
  const [company_country, setCompanycountry] = React.useState("");
  const [company_phone_number, setCompanypohnenumber] = React.useState("");
  const [company_email, setCompanyemail] = React.useState("");
  const [company_website, setCompanywebsite] = React.useState("");
  const [company_type, setCompanytype] = React.useState("");

  const [div1, setDiv1] = React.useState("block");
  const [div2, setDiv2] = React.useState("none");
  const [div3, setDiv3] = React.useState("none");
  const [div4, setDiv4] = React.useState("none");

  function next1() {
    if (
      isim === "" ||
      soyisim === "" ||
      Mail === "" ||
      password1 === "" ||
      password2 === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    if (password1 !== password2) {
      toast(langObject.registerNotSame, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (password1.length < 8 || password2.length < 8) {
      toast(langObject.registerMust8, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setDiv1("none");
    setDiv2("block");
    setDiv3("none");
    setDiv4("none");
  }

  function next2() {
    if (
      kimlikno === "" ||
      birthday === "" ||
      birth_place === "" ||
      countryCode === "" ||
      Telefon === "" ||
      sehir === "" ||
      adres === "" ||
      country === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setDiv1("none");
    setDiv2("none");
    setDiv3("block");
    setDiv4("none");
  }
  function next3() {
    if (
      company_name === "" ||
      company_email === "" ||
      company_website === "" ||
      company_phone_number === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setDiv1("none");
    setDiv2("none");
    setDiv3("none");
    setDiv4("block");
  }

  function register() {
    if (
      company_type === "" ||
      company_address === "" ||
      company_city === "" ||
      company_tax_number === "" ||
      company_country === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setDiv1("none");
    setDiv2("none");
    setDiv3("none");
    setDiv4("block");
  }

  function back1() {
    setDiv1("block");
    setDiv2("none");
    setDiv3("none");
    setDiv4("none");
  }
  function back2() {
    setDiv1("none");
    setDiv2("block");
    setDiv3("none");
    setDiv4("none");
  }
  function back3() {
    setDiv1("none");
    setDiv2("none");
    setDiv3("block");
    setDiv4("none");
  }
  const sifre1 = (event) => {
    setsifre(event.target.value);
  };

  const sifre2 = (event) => {
    setsifre2(event.target.value);
  };

  const isimAyarla = (event) => {
    setIsim(event.target.value);
  };

  const soyisimAyarla = (event) => {
    setSoyisim(event.target.value);
  };

  const mailAyarla = (event) => {
    setMail(event.target.value);
  };

  const countryCodeAyarla = (event) => {
    setCountryCode(event.target.value);
  };

  const TelefonAyarla = (event) => {
    setTelefon(event.target.value);
  };

  const birthdayAyarla = (event) => {
    let CurrentDate = new Date();
    let GivenDate = new Date(event.target.value);
    if (CurrentDate < GivenDate) {
      toast(langObject.registerDateMust, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setBirthday(event.target.value);
  };

  const birth_placeAyarla = (event) => {
    setBirthplace(event.target.value);
  };

  const sehirAyarla = (event) => {
    setSehir(event.target.value);
  };

  const countryAyarla = (event) => {
    setCountry(event.target.value);
  };

  const adresAyarla = (event) => {
    setAdres(event.target.value);
  };
  const kimliknoAyarla = (event) => {
    setKimlikno(event.target.value);
  };
  const company_countryAyarla = (event) => {
    setCompanycountry(event.target.value);
  };
  const company_nameAyarla = (event) => {
    setCompanyname(event.target.value);
  };
  const company_tax_numberAyarla = (event) => {
    setCompanytaxnumber(event.target.value);
  };
  const company_addressAyarla = (event) => {
    setCompanyaddress(event.target.value);
  };
  const company_cityAyarla = (event) => {
    setCompanycity(event.target.value);
  };
  const company_phone_numberAyarla = (event) => {
    setCompanypohnenumber(event.target.value);
  };
  const company_emailAyarla = (event) => {
    setCompanyemail(event.target.value);
  };
  const company_websiteAyarla = (event) => {
    setCompanywebsite(event.target.value);
  };
  const company_typeAyarla = (event) => {
    setCompanytype(event.target.value);
  };

  function gonderFunction() {
    if (
      company_type === "" ||
      company_address === "" ||
      company_city === "" ||
      company_tax_number === "" ||
      company_country === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (password1 === password2) {
      let gonderilecekVeriler = {
        name: isim,
        surname: soyisim,
        email: Mail,
        password: password1,
        country_code: countryCode,
        phone_number: Telefon,
        birthday: birthday,
        birth_place: birth_place,
        city: sehir,
        country: "90",
        address: adres,
        id_type: "90",
        id_number: kimlikno,
        company_name: company_name,
        company_tax_number: company_tax_number,
        company_address: company_address,
        company_city: company_city,
        company_phone_number: company_phone_number,
        company_email: company_email,
        company_country: company_country,
        company_website: company_website,
        company_type: company_type,
        api_key: "yup13kma513myxn4ff9og",
        api_secret: "cdubuccld7kn9zc2vdihfc",
      };

      axios
        .post(
          "https://acdigitalapi.com/CorporateRegister",
          gonderilecekVeriler,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((sonuc) => {
          if (sonuc.data.status == "error") {
            toast(sonuc.data.alert, {
              type: "error",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (sonuc.data.status === "success") {
            redirectToPin();
          } else {
            toast(sonuc.data.alert, {
              type: "success",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } else {
      toast(langObject.passwordNotMatch, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: colors.backgroundColor }}
    >
      <div className="container p-0 pt-5" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col-md-8">
            <img
              src={rightPhoto}
              className="d-flex"
              style={styles.securityPhoto}
            />
          </div>

          <div className="col-md-4 mt-5">
            <a href="home" className="d-block" style={{ textAlign: "center" }}>
              <img src={logo} width="auto" height="90px" className="mb-4" />
            </a>
            <h1>
              {" "}
              <FontAwesomeIcon icon="fa-solid fa-house" />
              {langObject.corporateRegister}
            </h1>

            <p className="fs-6 mt-4 mb-4">{langObject.forgetText}</p>
            <div id="1" style={{ display: div1 }}>
              {" "}
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3 mt-2">
                    <input
                      type="text"
                      onChange={isimAyarla}
                      value={isim}
                      className="form-control"
                      id="nameInput"
                      placeholder="name"
                      autoCorrect={false}
                      autoCapitalize="none"
                    />
                    <label for="nameInput">{langObject.registerName}</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3 mt-2">
                    <input
                      type="text"
                      onChange={soyisimAyarla}
                      value={soyisim}
                      className="form-control"
                      id="surnameInput"
                      placeholder="surname"
                      autoCapitalize="none"
                      autoCorrect={false}
                    />
                    <label for="nameInput">{langObject.registerSurname}</label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  value={Mail}
                  onChange={mailAyarla}
                  id="floatingInput"
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder="name@example.com"
                />
                <label for="floatingInput">{langObject.registerEmail}</label>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="password"
                    className="form-control mb-3"
                    onChange={sifre1}
                    value={password1}
                    placeholder={langObject.registerPassword}
                    style={{ height: "58px" }}
                    autoCapitalize="none"
                    autoCorrect={false}
                  />
                </div>
                <div className="col">
                  <input
                    type="password"
                    className="form-control mb-3"
                    onChange={sifre2}
                    value={password2}
                    placeholder={langObject.registerPassword}
                    autoCorrect={false}
                    autoCapitalize="none"
                    style={{ height: "58px" }}
                  />
                </div>
              </div>
              <div className="gap-2" style={{ textAlign: "right" }}>
                <input
                  type="button"
                  className="btn mt-2 mb-3"
                  onClick={next1}
                  autoCapitalize="none"
                  autoCorrect={false}
                  value={langObject.registerNext}
                  style={{
                    height: "58px",
                    width: "100%",
                    color: "#F0B90B",
                    backgroundColor: "white",
                    border: "1px solid #F0B90B",
                  }}
                />
              </div>
            </div>
            <div id="2" style={{ display: div2 }}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={kimliknoAyarla}
                  value={kimlikno}
                  id="kimlikInput"
                  maxLength="11"
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder="kimlikInput"
                />
                <label for="kimlikInput">{langObject.registerId}</label>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="date"
                    className="form-control mb-3"
                    onChange={birthdayAyarla}
                    value={birthday}
                    id="dateInput"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder="dateInput"
                    style={{ height: "58px" }}
                  />
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={birth_placeAyarla}
                      value={birth_place}
                      id="dogumYeriInput"
                      autoCapitalize="none"
                      autoCorrect={false}
                      placeholder="Place of Birth"
                    />
                    <label for="dogumYeriInput">
                      {langObject.registerBirth}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="countryInput"
                      onChange={countryCodeAyarla}
                      value={countryCode}
                      placeholder="Country Code"
                      autoCapitalize="none"
                      autoCorrect={false}
                    />
                    <label for="floatingInput">
                      {langObject.registerCountry}
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={TelefonAyarla}
                      value={Telefon}
                      id="phoneInput"
                      placeholder="Phone Number"
                      autoCorrect={false}
                      autoCapitalize="none"
                    />
                    <label for="phoneInput">{langObject.Phone}</label>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={sehirAyarla}
                    value={sehir}
                    id="cityInput"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder="cityInput"
                  />
                  <label className="ps-4" for="cityInput">
                    {langObject.registerCity}
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={adresAyarla}
                    value={adres}
                    autoCorrect={false}
                    id="addressInput"
                    autoCapitalize="none"
                    placeholder="addressInput"
                  />
                  <label className="ps-4" for="addressInput">
                    {langObject.registerAddress}
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={countryAyarla}
                    value={country}
                    autoCorrect={false}
                    id="countryInput"
                    autoCapitalize="none"
                    placeholder="countryInput"
                  />
                  <label className="ps-4" for="countryInput">
                    {langObject.registerCountry}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="gap-2" style={{ textAlign: "left" }}>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      autoCapitalize="none"
                      autoCorrect={false}
                      onClick={back1}
                      value={langObject.registerBack}
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "#F0B90B",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="gap-2" style={{ textAlign: "right" }}>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      onClick={next2}
                      value={langObject.registerNext}
                      autoCorrect={false}
                      autoCapitalize="none"
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "#F0B90B",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="3" style={{ display: div3 }}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_nameAyarla}
                  value={company_name}
                  autoCorrect={false}
                  id="companynameInput"
                  autoCapitalize="none"
                  placeholder="companynameInput"
                />
                <label for="companynameInput">{langObject.companyName}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_emailAyarla}
                  value={company_email}
                  autoCorrect={false}
                  id="companyemailInput"
                  autoCapitalize="none"
                  placeholder="companyemailInput"
                />
                <label for="companyemailInput">{langObject.companyMail}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_websiteAyarla}
                  value={company_website}
                  autoCorrect={false}
                  id="compnaywebsiteInput"
                  autoCapitalize="none"
                  placeholder="compnaywebsiteInput"
                />
                <label for="compnaywebsiteInput">{langObject.companyWeb}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_phone_numberAyarla}
                  value={company_phone_number}
                  autoCorrect={false}
                  id="companyphonenumberInput"
                  autoCapitalize="none"
                  placeholder="companyphonenumberInput"
                />
                <label for="companyphonenumberInput">
                  {langObject.companyPhone}
                </label>
              </div>

              <div className="row">
                <div className="col">
                  <div className="gap-2" style={{ textAlign: "left" }}>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      autoCapitalize="none"
                      autoCorrect={false}
                      onClick={back2}
                      value={langObject.registerBack}
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "#F0B90B",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="gap-2" style={{ textAlign: "right" }}>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      onClick={next3}
                      value={langObject.registerNext}
                      autoCorrect={false}
                      autoCapitalize="none"
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "#F0B90B",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="4" style={{ display: div4 }}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_typeAyarla}
                  value={company_type}
                  autoCorrect={false}
                  id="companytypeInput"
                  autoCapitalize="none"
                  placeholder="companytypeInput"
                />
                <label for="companytypeInput">{langObject.companyType}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_addressAyarla}
                  value={company_address}
                  autoCorrect={false}
                  id="companyaddressInput"
                  autoCapitalize="none"
                  placeholder="companyaddressInput"
                />
                <label for="companyaddressInput">
                  {langObject.companyAddress}
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_cityAyarla}
                  value={company_city}
                  autoCorrect={false}
                  id="companycityInput"
                  autoCapitalize="none"
                  placeholder="companycityInput"
                />
                <label for="companycityInput">{langObject.companyCity}</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_tax_numberAyarla}
                  value={company_tax_number}
                  autoCorrect={false}
                  id="companytaxnumberInput"
                  autoCapitalize="none"
                  placeholder="companytaxnumberInput"
                />
                <label for="companytaxnumberInput">
                  {langObject.companyTax}
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={company_countryAyarla}
                  value={company_country}
                  autoCorrect={false}
                  id="companycountryInput"
                  autoCapitalize="none"
                  placeholder="companycountryInput"
                />
                <label for="companycountryInput">
                  {langObject.companyCountry}
                </label>
              </div>

              <div className="row">
                <div className="col">
                  <div className="gap-2" style={{ textAlign: "left" }}>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      onClick={back3}
                      autoCorrect={false}
                      autoCapitalize="none"
                      value={langObject.registerBack}
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "#F0B90B",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="gap-2 text-left">
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      onClick={() => gonderFunction()}
                      value={langObject.register}
                      autoCapitalize="none"
                      autoCorrect={false}
                      maxLength="11"
                      style={{
                        height: "58px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#F0B90B",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-3" />

              <p className="text-center">
                {langObject.registerMember}
                <FontAwesomeIcon icon="coffee" size="xl" />
                <a
                  href="Login"
                  style={{
                    textDecoration: "none",
                    color: "#F0B90B",
                    marginLeft: "10px",
                  }}
                >
                  {langObject.LogIn}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ position: "absolute", bottom: "0" }} />
      <ToastContainer />
    </div>
  );
};

const styles = {
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "black",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  securityPhoto: {
    margin: "auto",
    width: "80%",
    height: "auto",
  },
  changeA: {
    textDecoration: "none",
    color: "black",
  },
};

export default CorporateRegisterPage;
