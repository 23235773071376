import React, { useState, useEffect } from "react";
import LoginMenu from "../Components/LoginMenu";
import { lightColors, darkColors } from "../Colors/Color";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import englishLogo from "../assets/img/englishLanguage.png";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import axios from "axios";
import { AiOutlineRight } from "react-icons/ai";
import Moment from "react-moment";
import "moment/locale/tr";
import LoginPage from "../Screens/Login";
import swapImage from "../assets/img/swap.png";
import "../assets/css/onlyDashboard.css";
import Swal from "sweetalert2";
import Footer from "../Components/Footer";

//react-modal
import { Modal, ModalHeader, ModalBody } from "react-modal";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserIndexPage = () => {
  const [theme, setTheme] = React.useState("");
  const [user_id, setUserId] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [assetList, setAssetList] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [ExchangeAsset1, setExchangeAsset1] = React.useState("TRY");
  const [ExchangeAsset2, setExchangeAsset2] = React.useState("EUR");
  const [walletData, setWalletData] = React.useState("");
  const [ExchangeData1, setExchangeData1] = React.useState("");
  const [ExchangeData2, setExchangeData2] = React.useState("");
  const [selectedCardId, setSelectedCardId] = React.useState("");
  const [selectedCardData, setSelectedCardData] = React.useState("");

  const [cardListArray, setCardListArray] = React.useState("");

  const [lastOperations, setLastOperations] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [asset, setAsset] = React.useState("6367c3e400ac884500039085");
  const [asset2, setAsset2] = React.useState("6367c3af00ac88450003907f");

  const [cardList, setCardList] = React.useState("");
  const [exchangeMiktar, setExchangeMiktar] = React.useState(0);
  const [account_type, setAccountType] = React.useState("");

  const [addMiktar, setAddMiktar] = React.useState("");
  const [withdrawMiktar, setWithdrawMiktar] = React.useState("");
  async function getData() {
    let result = sessionStorage.getItem("user_id");

    if (result) {
      setUserId(result);
    }
  }

  const notify = () => toast("Wow so easy!");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    setAddModal(false);
    setWithdrawModal(false);
    setAccountType(sessionStorage.getItem("account_type"));

    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
    setSelectedCardData("");
  }, []);

  useEffect(() => {
    getWallet("TRY");
    getWalletForExchange1("TRY");
    getWalletForExchange2("EUR");
    getUserInfo();
    getAssetList();
    getLastOperations();
    getCards();
  }, [user_id, account_type]);

  async function getUserInfo() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          window.location.href = "/login";
        }
      });
  }

  function getAssetList() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/assetList", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function changeExchangeAsset1(symbol, id) {
    getWalletForExchange1(symbol);
    setExchangeAsset1(symbol);
    setAsset(id);
  }

  function changeExchangeAsset2(symbol, id) {
    getWalletForExchange2(symbol);
    setExchangeAsset2(symbol);
    setAsset2(id);
  }

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          if (sonuc.data.data.balance.toString().includes(".")) {
            let explode = sonuc.data.data.balance.split(".");
            let explode2 = explode[1].substring(0, 2);

            let tryBakiyem2 = explode[0] + "." + explode2;

            sonuc.data.data.balance = tryBakiyem2;
          } else {
          }

          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getWalletForExchange1(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData1(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getWalletForExchange2(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData2(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getLastOperations() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      limit: 5,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post(
        "https://acdigitalapi.com/getmoneyoperations",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setLastOperations(sonuc.data.message);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }
  let assetListArray2 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray2.push(
          <li
            onClick={() =>
              changeExchangeAsset1(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let assetListArray3 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray3.push(
          <li
            onClick={() =>
              changeExchangeAsset2(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let lastOperationsArray = [];

  if (lastOperations) {
    for (let i = 0; i < lastOperations.length; i++) {
      lastOperationsArray.push(
        <div
          className="text-light"
          style={{ borderBottom: "1px solid rgb(58, 58, 58)" }}
        >
          <p
            className="text-secondary"
            style={{ fontSize: "14px", marginBottom: "4px", marginTop: "10px" }}
          >
            <Moment format="DD/MM/YYYY">{lastOperations[i].createdAt}</Moment>
          </p>
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <div className="col-lg-2" style={{ width: "auto" }}>
              <img
                src={lastOperations[i].image}
                style={{
                  width: "70px",
                  height: "70px",
                  paddingRight: "5px",
                }}
              ></img>
            </div>
            <div className="col-lg-7" style={{ width: "auto" }}>
              <div className="row flex-column">
                <div
                  className="col text-light fs-5"
                  style={{ fontWeight: "600" }}
                >
                  {lastOperations[i].title}
                </div>
                <div className="col text-secondary">
                  {lastOperations[i].explanation}
                </div>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                textAlign: "right",
                width: "auto",
                position: "absolute",
                right: "0",
              }}
            >
              <div className="row flex-column">
                <div className="col">
                  {parseFloat(lastOperations[i].amount).toFixed(2)}{" "}
                  {lastOperations[i].asset_icon}
                </div>
                <div className="col">
                  <p
                    className="text-secondary"
                    style={{ fontSize: "14px", marginBottom: "0" }}
                  >
                    <Moment format="HH:mm">
                      {lastOperations[i].createdAt}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  async function getExchange(miktar) {
    if (miktar == 0) {
      setExchangeMiktar("");
      return;
    }
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: miktar,
      from: asset,
      to: asset2,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      account_type: sessionStorage.getItem("account_type"),
    };
    let token = sessionStorage.getItem("token");

    axios
      .post("https://acdigitalapi.com/ExchangePanel", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeMiktar(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getCards() {
    if (
      sessionStorage.getItem("token") == null &&
      sessionStorage.getItem("token") == "" &&
      sessionStorage.getItem("token") == undefined
    ) {
      return;
    }
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetUserCard", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.message == "invalid_token") {
        }
        if (sonuc.data.status == "success") {
          setCardList(sonuc.data.message);
        }
      });
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  function changeFunction(text) {
    setAmount(text);
    getExchange(text);
  }

  function setSelectedCard(card_id) {
    setSelectedCardId(card_id);
    getCardData(card_id);
  }

  function getCardData(card_id) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      card_id: card_id,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/cardDetails", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setSelectedCardData(sonuc.data.message);
        }
      });
  }

  let cardListView = [];

  useEffect(() => {
    if (cardList.length > 0) {
      for (let i = 0; i < cardList.length; i++) {
        cardListView.push(
          <button
            onClick={() => setSelectedCard(cardList[i].card_id)}
            style={{
              backgroundColor: "#101010",
              border: "none",
              marginTop: "10px",
            }}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <img
                      src={cardList[i].card_photo}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "contain",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <h3
                      style={{
                        textAlign: "right",
                        color: "white",
                      }}
                    >
                      {cardList[i].card_name}
                    </h3>
                    <h5
                      style={{
                        textAlign: "right",
                        color: "white",
                      }}
                    >
                      <span className="acCardBalance">
                        {langObject.balance}:{" "}
                        {parseFloat(cardList[i].balance).toFixed(2)} ₺
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </button>
        );
      }

      setCardListArray(cardListView);
    }
  }, [cardList]);

  const [AddModal, setAddModal] = React.useState(false);
  const [WithdrawModal, setWithdrawModal] = React.useState(false);

  function openAddModal() {
    setAddModal(true);
  }

  function closeAddModal() {
    setAddModal(false);
  }

  function openWithdrawModal() {
    setWithdrawModal(true);
  }

  function closeWithdrawModal() {
    setWithdrawModal(false);
  }

  const styles = getStyles(colors);

  function changeMiktarAdd(text) {
    if (isNaN(text)) {
      return;
    }
    setAddMiktar(text);
  }

  const changeMiktarWithdraw = (event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    setWithdrawMiktar(event.target.value);
  };

  function addFunction(text) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      card_id: selectedCardId,
      amount: addMiktar,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };
    axios
      .post("https://acdigitalapi.com/AddBalanceToCard", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          toast(sonuc.data.alert, {
            type: "success",
          });

          closeAddModal();
          setAddMiktar("");
          getCards();
          getCardData(selectedCardId);
          getWallet(selectedAssetSymbol);
          getLastOperations();
        }

        if (sonuc.data.status == "failed") {
          toast(sonuc.data.alert, {
            type: "error",
          });
        }
        if (sonuc.data.status == "error") {
          toast(sonuc.data.alert, {
            type: "error",
          });
        }
      });
  }
  function modalAc1() {
    Swal.fire({
      title: langObject.swalTitle,
      text: langObject.swalText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.goldColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        addFunction();
      }
    });
  }
  function modalAc2() {
    Swal.fire({
      title: langObject.swalTitle,
      text: langObject.swalText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.goldColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        withdrawFunction();
      }
    });
  }
  function withdrawFunction() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      card_id: selectedCardId,
      amount: withdrawMiktar,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };
    axios
      .post("https://acdigitalapi.com/WithdrawCard", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          toast(sonuc.data.alert, {
            type: "success",
          });
          closeWithdrawModal();
          setWithdrawMiktar("");
          getCards();
          getCardData(selectedCardId);
          getWallet(selectedAssetSymbol);
          getLastOperations();
        }

        if (sonuc.data.status == "failed") {
          toast(sonuc.data.alert, {
            type: "error",
          });
        }
        if (sonuc.data.status == "error") {
          toast(sonuc.data.alert, {
            type: "error",
          });
        }
      });
  }

  function closeModals() {
    setAddModal(false);
    setWithdrawModal(false);
  }

  if (
    !userData ||
    !walletData ||
    !assetList ||
    !ExchangeData1 ||
    !ExchangeData2
  ) {
    return <div></div>;
  } else {
    return (
      <>
        <div
          className="container-fluid"
          style={{
            backgroundColor: colors.goldColor,
            height: "100%",
            width: "100%",
            padding: "0",
            overflow: "hidden",
          }}
        >
          <LoginMenu />
          <button
            onClick={() => closeModals()}
            className="row"
            style={{
              height: "100%",
              width: "100%",
              padding: "0",
              opacity: "0.7",
              position: "absolute",
              backgroundColor: "black",
              zIndex: "100",
              border: "none",
              display: AddModal || WithdrawModal ? "block" : "none",
            }}
          ></button>
          <div
            className="col-6 px-4 text-center changeModal"
            style={{
              backgroundColor: "black",
              border: "1px solid white",
              borderColor: "white",
              position: "absolute",
              alignSelf: "center",
              justifyContent: "center",
              height: "50%",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              zIndex: "110",
              margin: "auto",
              display: AddModal ? "block" : "none",
              borderRadius: "20px",
            }}
          >
            <h2 className="pt-3" style={{ color: colors.goldColor }}>
              {langObject.acCardAddBalance}
            </h2>
            <p style={{ color: colors.textGray }}>{langObject.acCardText}</p>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 offset-3 modalInput mb-3">
                    <p style={{ color: "white" }}>{selectedCardData.name}</p>
                    <input
                      type="text"
                      value={addMiktar}
                      onChange={(e) => changeMiktarAdd(e.target.value)}
                      className="form-control modalInput"
                      placeholder={langObject.amount}
                    />
                    <button
                      className="btn btn-primary mt-3 w-100 modalInput"
                      onClick={() => modalAc1()}
                      style={{
                        backgroundColor: colors.goldColor,
                        border: "none",
                      }}
                    >
                      {langObject.withdrawAddButton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-6 px-4 text-center changeModal"
            style={{
              backgroundColor: "black",
              border: "1px solid white",
              borderColor: "white",
              position: "absolute",
              alignSelf: "center",
              justifyContent: "center",
              height: "50%",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
              zIndex: "110",
              margin: "auto",
              display: WithdrawModal ? "block" : "none",
              borderRadius: "20px",
            }}
          >
            <h2 className="pt-3" style={{ color: colors.goldColor }}>
              {langObject.acCardWithdrawBalance}
            </h2>
            <p style={{ color: colors.textGray }}>
              {langObject.acCardWithdrawBalanceText}
            </p>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 offset-3 modalInput mb-3">
                    <p style={{ color: "white" }}>{selectedCardData.name}</p>
                    <input
                      type="text"
                      onChange={changeMiktarWithdraw}
                      className="form-control"
                      placeholder={langObject.amount}
                      value={withdrawMiktar}
                    />
                    <button
                      className="btn btn-primary mt-3 w-100"
                      onClick={() => modalAc2()}
                      style={{
                        backgroundColor: colors.goldColor,
                        border: "none",
                      }}
                    >
                      {langObject.Withdraw}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid"
            style={{
              borderTop: "1px solid rgb(58, 58, 58)",
              background: "#101010",
            }}
          >
            <div className="row">
              <div
                className="col-lg-4 scrollChange scrollHeight"
                style={{
                  borderRight: "1px solid rgb(58, 58, 58)",
                  height: "95vh",
                  padding: "0px 20px 10px 20px",
                  overflowY: "scroll",
                }}
              >
                <div className="row pt-3">
                  <div
                    className="col-lg-4 col-sm-2 accountImage"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <img
                      src={sessionStorage.getItem("avatar")}
                      style={{ width: "110px", borderRadius: "100%" }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm" style={{ width: "65%" }}>
                    <span
                      className="d-block fs-3 text-light"
                      style={{ fontWeight: "600" }}
                    >
                      {userData.name}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.email}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.phone_number}
                    </span>
                    <span className="d-block text-light">
                      {userData.user_account_number}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle pt-3"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "none", color: "white" }}
                    >
                      <span
                        style={
                          (styles2.menuNavLink,
                          {
                            fontFamily: "averta, Arial",
                            fontWeight: "600",
                            fontSize: "13px",
                          })
                        }
                      >
                        <span id="dashboardFlag">
                          <img
                            src={walletData.assetData.asset_logo}
                            width="25px"
                            height="25px"
                            style={{ borderRadius: "100%" }}
                          />
                        </span>
                        <span style={styles2.menuNavLink}>
                          {walletData.assetData.asset_name ?? ""}{" "}
                          {langObject.account}
                        </span>
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-dark logFix2 bankList"
                      style={{
                        width: "400px",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      {assetListArray}
                    </ul>
                  </div>
                  <h1
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    {parseFloat(walletData.balance).toFixed(2)}
                    {walletData.assetData.asset_icon ?? ""}
                  </h1>
                </div>
                <div className="row">
                  <div className="col-6">
                    <a
                      href="transfer"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-secondary w-100">
                        {langObject.moneyTransferButton}
                      </div>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="Withdraw"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-outline-secondary w-100">
                        {langObject.withdrawMoneyButton}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="lastOperations">
                  <a
                    href="transaction-page"
                    style={{ textDecoration: "none", fontWeight: "400" }}
                  >
                    <div className="h6 text-secondary my-4">
                      {langObject.lastOperations}
                      <AiOutlineRight style={{ marginTop: "-3px" }} />
                    </div>
                  </a>
                  {lastOperationsArray}
                </div>
              </div>

              <div
                className="col-lg-4 scrollChange"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                  background: "#101010",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <h2 className="pt-3" style={{ color: colors.goldColor }}>
                  {langObject.acCardMyCards}
                </h2>

                <a href="add-card" className="text-decoration-none text-light">
                  <div className="btn btn-secondary w-100 mb-3">
                    {langObject.acCardAddCard}
                  </div>
                </a>
                {cardListArray}
              </div>

              <div
                className="col-lg-4 px-4 text-light scrollChange text-center pageEnd"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                }}
              >
                <h2 className="pt-3" style={{ color: colors.goldColor }}>
                  {langObject.acCardCardDetails}
                </h2>
                <p style={{ color: colors.textGray }}>
                  {langObject.acCardDetailsAbout}
                </p>

                {selectedCardData == "" ? (
                  <div className="text-center">
                    <p>{langObject.acCardSelectCard}</p>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6 offset-3">
                          <p>{selectedCardData.name}</p>
                          <img
                            src={selectedCardData.card_photo}
                            style={{
                              width: "100%",
                              resizeMode: "contain",
                              alignSelf: "center",
                              justifyContent: "center",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div
                          className="col-6 offset-3 mt-3"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="acCardBalance">
                            {langObject.balance}:
                            {parseFloat(selectedCardData.balance).toFixed(2)} ₺
                          </p>
                          <button
                            className="btn btn-block btn-warning w-100"
                            onClick={() => openAddModal()}
                            style={{
                              color: "white",
                            }}
                          >
                            {langObject.withdrawAddButton}
                          </button>
                          <button
                            className="btn btn-block btn-danger w-100 mt-3"
                            onClick={() => openWithdrawModal()}
                            style={{}}
                          >
                            {langObject.Withdraw}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

const getStyles = (colors) => ({});

const styles2 = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  customModal: {
    width: "50%",
    height: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    position: "absolute",
    backgroundColor: "white",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

export default UserIndexPage;
