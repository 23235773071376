import React, { useEffect } from "react";
import "../main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { lightColors, darkColors } from "../Colors/Color";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import userAgreement from "../assets/agreement/AC-USER-AGREEMENT.pdf";
import distanceSalesAgreement from "../assets/agreement/AC-DISTANCE-SALES-AGREEMENT.pdf";
import goldPurchase from "../assets/agreement/GOLD-PURCHASE-SALES-BROKERAGE-AGREEMENT.pdf";

const Footer = () => {
  const [user_id, setUserId] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [accountType, setaccountType] = React.useState("");

  async function getData() {
    let result = await sessionStorage.getItem("user_id");
    let result2 = await sessionStorage.getItem("account_type");
    if (result) {
      setUserId(result);
      setaccountType(result2);
    }
  }

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  function changeTheme() {
    let theme = sessionStorage.getItem("theme");

    if (theme === "dark") {
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "light") {
      sessionStorage.setItem("theme", "dark");
    }
  }
  return (
    <div>
      <div style={{ width: "auto", textAlign: "center" }}>
        <a
          href={userAgreement}
          target={"_blank"}
          className="text-decoration-none"
        >
          <button
            style={{ textDecoration: "none", color: colors.textGray }}
            className="btn m-0 pe-3 d-inline"
          >
            {langObject.userAgreement}
          </button>
        </a>
        <a
          href={distanceSalesAgreement}
          target={"_blank"}
          className="text-decoration-none"
        >
          <button
            style={{ textDecoration: "none", color: colors.textGray }}
            className="btn m-0 pe-3 d-inline"
          >
            {" "}
            {langObject.distanceAgreement}
          </button>
        </a>
        <a
          href={goldPurchase}
          target={"_blank"}
          className="text-decoration-none"
        >
          <button
            style={{ textDecoration: "none", color: colors.textGray }}
            className="btn m-0 pe-3 d-inline"
          >
            {langObject.brokerageAgreement}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Footer;
