import React, { useState, useEffect } from "react";
import Menu2 from "../Components/Menu2";
import { Col, Nav, Row, Tab, Accordion, Button } from "react-bootstrap";
import { lightColors, darkColors } from "../Colors/Color";
import Footer from "../Components/Footer";
import { TickerTape } from "react-tradingview-embed";

const ServicesPage = () => {
  document.body.style.backgroundColor = "white";
  document.body.style.overflowY = "auto";
  document.body.style.overflowX = "hidden";

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    const script = document.createElement("script");

    document.body.appendChild(script);
    let theme = sessionStorage.getItem("theme");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const styles = getStyles(colors);
  return (
    <div
      className="p-0 m-0"
      style={{ backgroundColor: "white", position: "relative" }}
    >
      <div
        className="container p-4 resContainer indexResTop "
        style={{
          minHeight: "100vh",
          backgroundColor: "white",
        }}
      >
        <Menu2 />

        <Tab.Container id="left-tabs-example" defaultActiveKey="goldTransfer">
          <Row className="mt-4">
            <Col md={4}>
              <Nav
                variant="pills"
                bg="dark"
                color="black"
                className="flex-column"
              >
                <Nav.Item style={{ marginBottom: 3 }}>
                  <Nav.Link
                    className="ServicesMenu"
                    style={{ textAlign: "left" }}
                    eventKey="goldPayment"
                  >
                    {langObject.servicesGoldPaymentSystem}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className=" p-0 text-dark">
                    <Accordion className="p-0" defaultActiveKey="0">
                      <Accordion.Item
                        className="border-0 p-0"
                        style={{ padding: "0" }}
                      >
                        <Accordion.Header className="ServicesMenu accItem">
                          {langObject.servicesGoldCard}
                        </Accordion.Header>
                        <Accordion.Body className="p-0 mt-1">
                          <Nav.Item>
                            <Nav.Link
                              className="ServicesMenu2"
                              eventKey="Yourself"
                            >
                              {langObject.servicesForYourself}
                            </Nav.Link>
                          </Nav.Item>
                        </Accordion.Body>
                        <Accordion.Body className="p-0 mt-1">
                          <Nav.Item className="p-0">
                            <Nav.Link
                              className="ServicesMenu2"
                              eventKey="Business"
                            >
                              {langObject.servicesForYourBusiness}
                            </Nav.Link>
                          </Nav.Item>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="ServicesMenu"
                    style={{ textAlign: "left" }}
                    eventKey="goldSpending"
                  >
                    {langObject.servicesGoldSpendingCard}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="ServicesMenu"
                    style={{ textAlign: "left" }}
                    eventKey="goldTransfer"
                  >
                    {langObject.servicesGoldTransfer}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="ServicesMenu"
                    eventKey="internationalTransactions"
                    style={{ textAlign: "left" }}
                  >
                    {langObject.servicesPhysicalGoldOperations}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={8}>
              <Tab.Content>
                <Tab.Pane eventKey="Yourself">
                  <div className="blog-content p-3">
                    <h3 className="blog-title my-3">
                      {langObject.servicesForYourself}
                    </h3>
                    <p>{langObject.servicesYourselfText1}</p>
                    <br />
                    <p>
                      <b>{langObject.servicesYourselfText2}</b>
                    </p>
                    <br />
                    <p> {langObject.servicesYourselfText3}</p>
                    <br />
                    <p>
                      {" "}
                      <b> {langObject.servicesYourselfText4}</b>
                    </p>
                    <Button href="register" className="btn-warning btn-lg">
                      {langObject.servicesButton}
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Business">
                  <div className="blog-content p-4">
                    <h3 className="blog-title my-3">
                      {langObject.servicesForYourBusiness}
                    </h3>
                    <p>{langObject.servicesBusinessText1}</p>
                    <p>{langObject.servicesBusinessText2}</p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="goldSpending">
                  {" "}
                  <div className="blog-content p-4">
                    <h3 className="blog-title my-3">
                      {langObject.servicesGoldSpendingCard}
                    </h3>
                    <p>{langObject.servicesSpendingText1}</p>
                    <br />
                    <p>{langObject.servicesSpendingText2}</p>
                    <br />
                    <p>
                      <b>{langObject.servicesSpendingText3}</b>
                    </p>
                    <br />
                    <p>{langObject.servicesSpendingText4}</p>
                    <br />
                    <p>
                      {" "}
                      <b>{langObject.servicesSpendingText5}</b>
                    </p>
                    <Button href="register" className="btn-warning btn-lg">
                      {langObject.servicesButton}
                    </Button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="goldTransfer">
                  {" "}
                  <div className="blog-content p-4">
                    <h3 className="blog-title my-3">
                      {langObject.servicesGoldTransfer}
                    </h3>
                    <p>{langObject.servicesGoldTransferText1}</p>
                    <br />
                    <p>{langObject.servicesGoldTransferText2}</p>
                    <br />
                    <p>
                      <b>{langObject.servicesGoldTransferText3}</b>
                    </p>
                    <Button href="register" className="btn-warning btn-lg">
                      {langObject.servicesButton}
                    </Button>
                  </div>{" "}
                </Tab.Pane>
                <Tab.Pane eventKey="internationalTransactions">
                  {" "}
                  <div className="blog-content p-4">
                    <h3 className="blog-title my-3">
                      {langObject.servicesPhysicalGoldTransactions}
                    </h3>
                    <p>{langObject.servicesTransactionText1}</p>
                    <br />
                    <p>{langObject.servicesTransactionText2}</p>
                    <br /> <p>{langObject.servicesTransactionText3}</p>
                    <p className="mt-2">
                      <b>{langObject.servicesTransactionText4}</b>
                    </p>
                    <br />
                    <Button href="register" className="btn-warning btn-lg">
                      {" "}
                      {langObject.servicesButton}
                    </Button>
                  </div>{" "}
                </Tab.Pane>
                <Tab.Pane eventKey="goldPayment">
                  {" "}
                  <div class="blog-content p-4">
                    <h3 class="blog-title my-3">
                      {langObject.servicesAltinCuzdan}
                    </h3>
                    <p>{langObject.servicePaymentText1}</p>
                    <p>{langObject.servicePaymentText2}</p>
                    <p>{langObject.servicePaymentText3}</p>
                    <ul>
                      <li style={{ fontSize: "18px" }}>
                        {langObject.servicePaymentText4}
                      </li>
                      <ul style={{ paddingLeft: "30px" }}>
                        <li>{langObject.servicePaymentText5}</li>
                        <li>{langObject.servicePaymentText6}</li>
                        <li>{langObject.servicePaymentText7}</li>
                        <li>{langObject.servicePaymentText8}</li>
                        <li>{langObject.servicePaymentText9}</li>
                      </ul>
                      <br />
                      <li style={{ fontSize: "18px" }}>
                        {langObject.servicePaymentText10}
                      </li>

                      <ul style={{ paddingLeft: "30px" }}>
                        <li>{langObject.servicePaymentText11}</li>
                      </ul>
                      <br />
                      <li style={{ fontSize: "18px" }}>
                        {langObject.servicePaymentText12}
                      </li>
                      <ul style={{ paddingLeft: "30px" }}>
                        <li>{langObject.servicePaymentText13}</li>
                        <li>{langObject.servicePaymentText14}</li>
                        <li>{langObject.servicePaymentText15}</li>
                      </ul>
                      <br />
                      <li style={{ fontSize: "18px" }}>
                        {langObject.servicePaymentText16}
                      </li>
                      <ul style={{ paddingLeft: "30px" }}>
                        <li>{langObject.servicePaymentText17}</li>
                        <li>{langObject.servicePaymentText18}</li>
                        <li>{langObject.servicePaymentText19}</li>
                      </ul>
                    </ul>
                    <p>
                      <b>{langObject.servicePaymentText20}</b>
                    </p>
                    <br />
                    <Button href="register" className="btn-warning btn-lg">
                      {langObject.servicesButton}
                    </Button>
                  </div>{" "}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <Footer style={{ paddingBottom: "20px" }} />
      <div className="mb-5 tickerTape"> </div>{" "}
      {/* Position fixed arası boşluk */}
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          background: "#1e222d",
        }}
      >
        <div className="pb-1 ">
          <TickerTape widgetProps={{ theme: "dark" }}></TickerTape>
        </div>
      </div>
    </div>
  );
};

const getStyles = (colors) => ({
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "white",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  flagDoviz: {
    borderRadius: "100%",
    float: "left",
    width: "18px",
  },
});

export default ServicesPage;
