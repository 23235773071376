import React, {
  useState,
  useEffect,
  ActivityIndicator,
  Text,
  Image,
} from "react";
import LoginMenu from "../Components/LoginMenu";
import { lightColors, darkColors } from "../Colors/Color";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import englishLogo from "../assets/img/englishLanguage.png";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import axios from "axios";
import { AiOutlineRight } from "react-icons/ai";
import Moment from "react-moment";
import "moment/locale/tr";
import LoginPage from "../Screens/Login";
import swapImage from "../assets/img/swap.png";
import "../assets/css/onlyDashboard.css";
import Swal from "sweetalert2";
import Footer from "../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserIndexPage = () => {
  const [theme, setTheme] = React.useState("");
  const [user_id, setUserId] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [assetList, setAssetList] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [ExchangeAsset1, setExchangeAsset1] = React.useState("TRY");
  const [ExchangeAsset2, setExchangeAsset2] = React.useState("EUR");
  const [walletData, setWalletData] = React.useState("");
  const [ExchangeData1, setExchangeData1] = React.useState("");
  const [ExchangeData2, setExchangeData2] = React.useState("");

  const [lastOperations, setLastOperations] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [asset, setAsset] = React.useState("6367c3e400ac884500039085");
  const [asset2, setAsset2] = React.useState("6367c3af00ac88450003907f");
  const [selectedBankId, setSelectedBankId] = React.useState("");
  const [selectedBankImage, setSelectedBankImage] = React.useState("");
  const [tryBakiyem, setTryBakiyem] = useState("");
  const [selectediban, setselectediban] = useState();

  const [showBankalar, setShowBankalar] = React.useState("");

  const [exchangeMiktar, setExchangeMiktar] = React.useState(0);

  const [token, setToken] = useState("");

  const [bank, setBank] = useState("");
  const [account_type, setAccountType] = useState("");

  async function getData() {
    let result = sessionStorage.getItem("user_id");

    if (result) {
      setUserId(result);
    }
  }

  const notify = () => toast("Wow so easy!");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    setAccountType(sessionStorage.getItem("account_type"));

    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    getWallet("TRY");
    getWalletForExchange1("TRY");
    getWalletForExchange2("EUR");
    getUserInfo();
    getAssetList();
    getLastOperations();
    getBanks();
    getBakiye();
  }, [user_id, account_type]);

  async function getUserInfo() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          window.location.href = "/login";
        }
      });
  }

  function getAssetList() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/assetList", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getBanks() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post(
        "https://acdigitalapi.com/getRegisteredBanks",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.status == "success") {
          setBank(response.data.message);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function changeExchangeAsset1(symbol, id) {
    getWalletForExchange1(symbol);
    setExchangeAsset1(symbol);
    setAsset(id);
  }

  function changeExchangeAsset2(symbol, id) {
    getWalletForExchange2(symbol);
    setExchangeAsset2(symbol);
    setAsset2(id);
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const styles = getStyles(colors);

  function selectBank(gelen_id, image, iban) {
    setSelectedBankId(gelen_id);
    setSelectedBankImage(image);
    setselectediban(iban);
  }

  useEffect(() => {
    let bankalar = [];
    if (bank.length > 0) {
      for (let i = 0; i < bank.length; i++) {
        bankalar.push(
          <button
            onClick={() =>
              selectBank(bank[i].bank_id, bank[i].bank_image, bank[i].bank_iban)
            }
            style={{
              borderRadius: "15px",
              width: "100%",
              marginTop: "10px",
              padding: "10px",
            }}
            key={i}
          >
            <div style={styles2.divSol}>
              {bank[i].bank_image ? (
                <img
                  src={bank[i].bank_image}
                  style={{
                    width: "40%",
                    resizeMode: "contain",
                    borderRadius: 10,
                    tintColor: theme == "dark" ? "white" : "",
                    alignSelf: "center",
                  }}
                />
              ) : (
                <p></p>
              )}
            </div>
            <div style={styles2.divOrta}>
              <h3
                style={{ fontSize: 16, fontWeight: "500", color: colors.text }}
              >
                {bank[i].bank_name}
              </h3>
              <h3
                style={{
                  fontSize: 14,
                  fontWeight: "400",
                }}
              >
                <div className="text-dark">{bank[i].bank_iban}</div>
              </h3>
            </div>
          </button>
        );
      }
    } else {
      bankalar.push(
        <div
          key={1}
          style={{ width: "100%", alignSelf: "center", alignItems: "center" }}
        >
          <h3 style={{ color: "white" }}>{langObject.withdrawNoBanks}</h3>
        </div>
      );
    }
    setShowBankalar(bankalar);
  }, [bank]);

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          if (sonuc.data.data.balance.toString().includes(".")) {
            let explode = sonuc.data.data.balance.split(".");
            let explode2 = explode[1].substring(0, 2);

            let tryBakiyem2 = explode[0] + "." + explode2;

            sonuc.data.data.balance = tryBakiyem2;
          } else {
          }

          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getWalletForExchange1(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData1(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getWalletForExchange2(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData2(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getLastOperations() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      limit: 5,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post(
        "https://acdigitalapi.com/getmoneyoperations",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setLastOperations(sonuc.data.message);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }
  let assetListArray2 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray2.push(
          <li
            onClick={() =>
              changeExchangeAsset1(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let assetListArray3 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray3.push(
          <li
            onClick={() =>
              changeExchangeAsset2(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let lastOperationsArray = [];

  if (lastOperations) {
    for (let i = 0; i < lastOperations.length; i++) {
      lastOperationsArray.push(
        <div
          className="text-light"
          style={{ borderBottom: "1px solid rgb(58, 58, 58)" }}
        >
          <p
            className="text-secondary"
            style={{ fontSize: "14px", marginBottom: "4px", marginTop: "10px" }}
          >
            <Moment format="DD/MM/YYYY">{lastOperations[i].createdAt}</Moment>
          </p>
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <div className="col-lg-2" style={{ width: "auto" }}>
              <img
                src={lastOperations[i].image}
                style={{
                  width: "70px",
                  height: "70px",
                  paddingRight: "5px",
                }}
              ></img>
            </div>
            <div className="col-lg-7" style={{ width: "auto" }}>
              <div className="row flex-column">
                <div
                  className="col text-light fs-5"
                  style={{ fontWeight: "600" }}
                >
                  {lastOperations[i].title}
                </div>
                <div className="col text-secondary">
                  {lastOperations[i].explanation}
                </div>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                textAlign: "right",
                width: "auto",
                position: "absolute",
                right: "0",
              }}
            >
              <div className="row flex-column">
                <div className="col">
                  {parseFloat(lastOperations[i].amount).toFixed(2)}{" "}
                  {lastOperations[i].asset_icon}
                </div>
                <div className="col">
                  <p
                    className="text-secondary"
                    style={{ fontSize: "14px", marginBottom: "0" }}
                  >
                    <Moment format="HH:mm">
                      {lastOperations[i].createdAt}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function modalAc() {
    if (
      amount == 0 ||
      amount == "" ||
      amount == null ||
      amount == undefined ||
      amount == NaN ||
      amount < 0
    ) {
      toast.error(langObject.validAmount);
      return;
    }

    Swal.fire({
      title: langObject.swalTitle,
      text: langObject.swalText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.goldColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        talepet();
      }
    });
  }

  async function getExchange(miktar) {
    if (miktar == 0) {
      setExchangeMiktar(0);
      return;
    }
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: miktar,
      from: asset,
      to: asset2,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      account_type: sessionStorage.getItem("account_type"),
    };
    let token = sessionStorage.getItem("token");

    axios
      .post("https://acdigitalapi.com/ExchangePanel", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeMiktar(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function maxButton() {
    let explode = tryBakiyem.split(".");
    let explode2 = explode[1].substring(0, 2);

    let tryBakiyem2 = explode[0] + "." + explode2;
    setAmount(tryBakiyem2);
  }

  function getBakiye() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/getTRYBalance", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })

      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.data.toString().includes(".")) {
            let explode = response.data.data.split(".");
            let explode2 = explode[1].substring(0, 2);

            let tryBakiyem2 = explode[0] + "." + explode2;

            setTryBakiyem(tryBakiyem2);
          } else {
            setTryBakiyem(response.data.data);
          }
        }
        if (response.data.status == "failed") {
        }

        if (response.data.status == "error") {
        }
      })
      .catch((error) => {});
  }

  function changeFunction(text) {
    if (isNaN(text)) {
      return;
    }

    setAmount(text);
    getExchange(text);
  }

  function talepet() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: amount,
      bank_id: selectedBankId,
      iban: selectediban,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/AddWithdraw", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })

      .then((response) => {
        if (response.data.status == "success") {
          toast(response.data.alert, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast(response.data.alert, {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        if (response.data.status == "success") {
          getLastOperations();
          getWallet(selectedAssetSymbol);
        }

        if (response.data.status == "error") {
        }

        getBakiye();
        setAmount("");
      })
      .catch((error) => {});
  }

  if (
    !userData ||
    !walletData ||
    !assetList ||
    !ExchangeData1 ||
    !ExchangeData2
  ) {
    return <div></div>;
  } else {
    return (
      <>
        <div
          className="container-fluid"
          style={{
            backgroundColor: colors.goldColor,
            height: "100%",
            width: "100%",
            padding: "0",
            overflow: "hidden",
          }}
        >
          <LoginMenu />
          <div
            className="container-fluid"
            style={{
              borderTop: "1px solid rgb(58, 58, 58)",
              background: "#101010",
            }}
          >
            <div className="row">
              <div
                className="col-lg-4 scrollChange scrollHeight"
                style={{
                  borderRight: "1px solid rgb(58, 58, 58)",
                  height: "95vh",
                  padding: "0px 20px 10px 20px",
                  overflowY: "scroll",
                }}
              >
                <div className="row pt-3">
                  <div
                    className="col-lg-4 col-sm-2 accountImage"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <img
                      src={sessionStorage.getItem("avatar")}
                      style={{ width: "110px", borderRadius: "100%" }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm" style={{ width: "65%" }}>
                    <span
                      className="d-block fs-3 text-light"
                      style={{ fontWeight: "600" }}
                    >
                      {userData.name}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.email}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.phone_number}
                    </span>
                    <span className="d-block text-light">
                      {userData.user_account_number}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle pt-3"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "none", color: "white" }}
                    >
                      <span
                        style={
                          (styles2.menuNavLink,
                          {
                            fontFamily: "averta, Arial",
                            fontWeight: "600",
                            fontSize: "13px",
                          })
                        }
                      >
                        <span id="dashboardFlag">
                          <img
                            src={walletData.assetData.asset_logo}
                            width="25px"
                            height="25px"
                            style={{ borderRadius: "100%" }}
                          />
                        </span>
                        <span style={styles2.menuNavLink}>
                          {walletData.assetData.asset_name ?? ""}{" "}
                          {langObject.account}
                        </span>
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-dark logFix2 bankList"
                      style={{
                        width: "400px",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      {assetListArray}
                    </ul>
                  </div>
                  <h1
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    {parseFloat(walletData.balance).toFixed(2)}
                    {walletData.assetData.asset_icon ?? ""}
                  </h1>
                </div>
                <div className="row">
                  <div className="col-6">
                    <a
                      href="transfer"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-secondary w-100">
                        {langObject.moneyTransferButton}
                      </div>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="Withdraw"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-outline-secondary w-100">
                        {langObject.withdrawMoneyButton}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="lastOperations">
                  <a
                    href="transaction-page"
                    style={{ textDecoration: "none", fontWeight: "400" }}
                  >
                    <div className="h6 text-secondary my-4">
                      {langObject.lastOperations}
                      <AiOutlineRight style={{ marginTop: "-3px" }} />
                    </div>
                  </a>
                  {lastOperationsArray}
                </div>
              </div>

              <div
                className="col-lg-4 scrollChange"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                  background: "#101010",
                  color: "white",
                }}
              >
                <h3
                  className="pt-3"
                  style={{
                    color: colors.goldColor,
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {langObject.withdrawChooseBank}
                </h3>
                <h6>{langObject.withdrawText1}</h6>
                {"\n"}
                <p>{langObject.withdrawText2}</p>
                {showBankalar}
                <a
                  href="add-bank-account"
                  className="btn btn-block btn-warning w-100 mb-3"
                  style={{ marginTop: 20 }}
                >
                  {langObject.withdrawAddButton}
                </a>
              </div>

              <div
                className="col-lg-4 px-4 text-light scrollChange text-center"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                }}
              >
                <h2 className="pt-3" style={{ color: colors.goldColor }}>
                  {langObject.withdrawProcess}
                </h2>
                <p style={{ color: colors.textGray }}>
                  {langObject.withdrayMoneyTransfer}
                </p>

                {selectedBankId == "" ? (
                  <p>{langObject.withdrawSelectBank}</p>
                ) : (
                  <>
                    <div
                      style={{
                        height: "60px",
                        background: "#e9ecef",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        borderRadius: "15px",
                        padding: "10px",
                      }}
                    >
                      <img
                        src={selectedBankImage}
                        className="withdrawImage"
                        style={{
                          /* width: "50%", */
                          resizeMode: "contain",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                    <div class="input-group mb-3">
                      <span
                        class="input-group-text"
                        style={{
                          border: "2px solid " + colors.goldColor,
                          borderColor: colors.goldColor,
                          borderRight: "0",
                        }}
                      >
                        {langObject.amount}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        value={amount}
                        aria-label="Amount (to the nearest dollar)"
                        onChange={(e) => changeFunction(e.target.value)}
                        style={{
                          border: "2px solid " + colors.goldColor,
                          borderColor: colors.goldColor,
                          borderRight: "0",
                          borderLeft: "0",
                        }}
                      />
                      <span
                        class="input-group-text"
                        style={{
                          border: "2px solid " + colors.goldColor,
                          borderColor: colors.goldColor,
                          borderLeft: "0",
                          background: "white",
                        }}
                      >
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => maxButton()}
                        >
                          Max
                        </button>
                      </span>
                    </div>
                    <p>
                      {langObject.balance}: {tryBakiyem} ₺
                    </p>
                    <button
                      className="btn btn-block btn-warning w-100 pageEnd"
                      onClick={() => modalAc()}
                    >
                      {langObject.Withdraw}
                    </button>
                  </>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

const styles2 = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
  div: {
    width: "100%",
    marginTop: 15,
  },
};

const getStyles = (colors) => ({});

export default UserIndexPage;
