export const lightColors = {
  backgroundColor: "white",
  text: "black",
  placeholderColor: "#e68a00",
  statusbar: "dark",
  footer: "#101010",
  footerColor: "white",
  textGray: "#8e8e8e",
  goldColor: "#F0B90B",
  buttonBackground: "#F0B90B",
  buttonTextColor: "black",
};

export const darkColors = {
  backgroundColor: "#101010",
  text: "#FFFFFF",
  placeholderColor: "gray",
  statusbar: "light",
  footer: "#101010",
  footerColor: "white",
  textGray: "#8e8e8e",
  goldColor: "#F0B90B",
  buttonBackground: "#F0B90B",
  buttonTextColor: "white",
};
