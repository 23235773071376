import React, { useState, useEffect } from "react";
import LoginMenu from "../Components/LoginMenu";
import { lightColors, darkColors } from "../Colors/Color";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import englishLogo from "../assets/img/englishLanguage.png";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import axios from "axios";
import { AiOutlineRight, AiFillCopy } from "react-icons/ai";
import Moment from "react-moment";
import "moment/locale/tr";
import LoginPage from "../Screens/Login";
import swapImage from "../assets/img/swap.png";
import "../assets/css/onlyDashboard.css";
import Footer from "../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserIndexPage = () => {
  const [theme, setTheme] = React.useState("");
  const [user_id, setUserId] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [assetList, setAssetList] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [ExchangeAsset1, setExchangeAsset1] = React.useState("TRY");
  const [ExchangeAsset2, setExchangeAsset2] = React.useState("EUR");
  const [walletData, setWalletData] = React.useState("");
  const [ExchangeData1, setExchangeData1] = React.useState("");
  const [ExchangeData2, setExchangeData2] = React.useState("");

  const [lastOperations, setLastOperations] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [asset, setAsset] = React.useState("6367c3e400ac884500039085");
  const [asset2, setAsset2] = React.useState("6367c3af00ac88450003907f");

  const [bankaBilgileriArray, setBankaBilgileriArray] = React.useState("");
  const [BankList, setBankList] = React.useState("");

  const [bankListView, setBankListView] = React.useState("");

  const [bankaBilgileri, setBankaBilgileri] = React.useState("");
  const [exchangeMiktar, setExchangeMiktar] = React.useState(0);
  const [account_type, setAccountType] = React.useState("");
  const [selectedBankId, setSelectedBankId] = React.useState("");

  async function getData() {
    let result = sessionStorage.getItem("user_id");

    if (result) {
      setUserId(result);
    }
  }

  const notify = () => toast("Wow so easy!");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    setAccountType(sessionStorage.getItem("account_type"));

    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    getWallet("TRY");
    getUserInfo();
    getAssetList();
    getLastOperations();

    getBankalar();
  }, [user_id, account_type]);

  async function getUserInfo() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          window.location.href = "/login";
        }
      });
  }

  function getAssetList() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/assetList", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function changeExchangeAsset1(symbol, id) {
    setExchangeAsset1(symbol);
    setAsset(id);
  }

  function changeExchangeAsset2(symbol, id) {
    setExchangeAsset2(symbol);
    setAsset2(id);
  }

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          if (sonuc.data.data.balance.toString().includes(".")) {
            let explode = sonuc.data.data.balance.split(".");
            let explode2 = explode[1].substring(0, 2);

            let tryBakiyem2 = explode[0] + "." + explode2;

            sonuc.data.data.balance = tryBakiyem2;
          } else {
          }

          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getLastOperations() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      limit: 5,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post(
        "https://acdigitalapi.com/getmoneyoperations",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setLastOperations(sonuc.data.message);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }
  let assetListArray2 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray2.push(
          <li
            onClick={() =>
              changeExchangeAsset1(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let assetListArray3 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray3.push(
          <li
            onClick={() =>
              changeExchangeAsset2(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }
  let lastOperationsArray = [];

  if (lastOperations) {
    for (let i = 0; i < lastOperations.length; i++) {
      lastOperationsArray.push(
        <div
          className="text-light"
          style={{ borderBottom: "1px solid rgb(58, 58, 58)" }}
        >
          <p
            className="text-secondary"
            style={{ fontSize: "14px", marginBottom: "4px", marginTop: "10px" }}
          >
            <Moment format="DD/MM/YYYY">{lastOperations[i].createdAt}</Moment>
          </p>
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <div className="col-lg-2" style={{ width: "auto" }}>
              <img
                src={lastOperations[i].image}
                style={{
                  width: "70px",
                  height: "70px",
                  paddingRight: "5px",
                }}
              ></img>
            </div>
            <div className="col-lg-7" style={{ width: "auto" }}>
              <div className="row flex-column">
                <div
                  className="col text-light fs-5"
                  style={{ fontWeight: "600" }}
                >
                  {lastOperations[i].title}
                </div>
                <div className="col text-secondary">
                  {lastOperations[i].explanation}
                </div>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                textAlign: "right",
                width: "auto",
                position: "absolute",
                right: "0",
              }}
            >
              <div className="row flex-column">
                <div className="col">
                  {parseFloat(lastOperations[i].amount).toFixed(2)}{" "}
                  {lastOperations[i].asset_icon}
                </div>
                <div className="col">
                  <p
                    className="text-secondary"
                    style={{ fontSize: "14px", marginBottom: "0" }}
                  >
                    <Moment format="HH:mm">
                      {lastOperations[i].createdAt}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  async function getExchange(miktar) {
    if (miktar == 0) {
      setExchangeMiktar(0);
      return;
    }
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: miktar,
      from: asset,
      to: asset2,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      account_type: sessionStorage.getItem("account_type"),
    };
    let token = sessionStorage.getItem("token");

    axios
      .post("https://acdigitalapi.com/ExchangePanel", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeMiktar(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  function changeFunction(text) {
    setAmount(text);
    getExchange(text);
  }

  async function deposit(bank_id) {
    let gonderilecekVeriler = {
      bank_id: bank_id,
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    let bankaArray = [];
    await axios
      .post("https://acdigitalapi.com/getBankDetails", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setBankaBilgileri(sonuc.data.message);

          bankaArray.push(
            <div
              className="col-4"
              style={{
                width: "20rem",
                borderRadius: 10,
                border: "none",
                margin: "auto",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  height: "50px",
                  background: "buttonface",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "auto",
                }}
              >
                <img
                  style={{
                    width: "auto",
                    marginTop: 20,
                    alignSelf: "center",
                    margin: "auto",
                    justifyContent: "center",
                    height: "100%",
                    resizeMode: "contain",
                    alt: "bankImage",
                  }}
                  src={sonuc.data.message.bank_image}
                />
              </div>
              <h5 className="card-title" style={{ marginTop: 15 }}>
                {sonuc.data.message.user_name}
              </h5>
              <h6
                className="card-subtitle mb-2"
                style={{ marginTop: 15, color: colors.goldColor }}
              >
                <span style={{ fontWeight: "600", color: "#fff" }}>
                  {langObject.depositAccountNumber}
                </span>
                {sonuc.data.message.user_account_number}
              </h6>
              <h6
                className="card-subtitle mb-2"
                style={{ marginTop: 15, color: colors.goldColor }}
              >
                <span style={{ fontWeight: "600", color: "#fff" }}>
                  {langObject.depositSwiftCode}
                </span>
                <span className="card-text" style={{ marginTop: 15 }}>
                  {sonuc.data.message.bank_swift}
                </span>
              </h6>

              <h6
                className="card-subtitle mb-2"
                style={{ marginTop: 15, color: colors.goldColor }}
              >
                <span style={{ fontWeight: "600", color: "#fff" }}>
                  {langObject.depositAccountHolder}
                </span>
                <span className="card-text" style={{ marginTop: 15 }}>
                  {sonuc.data.message.account_holder}
                </span>
              </h6>
              <h6
                className="card-text d-flex"
                style={{
                  marginTop: 15,
                  position: "relative",
                }}
              >
                {sonuc.data.message.bank_iban}
                <AiFillCopy className="copyIcon" />
              </h6>
              <p className="card-text" style={{ marginTop: 15 }}>
                {sonuc.data.message.bank_name}
              </p>
            </div>
          );

          setBankaBilgileriArray(bankaArray);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  async function getBankalar() {
    let gonderilecekVeriler = {
      account_type: account_type,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      type: 1,
    };

    axios
      .post("https://acdigitalapi.com/getBank", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.status == "success") {
          setBankList(response.data.message);
        }
      });
  }

  function setSelectedBank(bankaId) {
    setSelectedBankId(bankaId);
    deposit(bankaId);
  }

  useEffect(() => {
    let bankaListesi = [];
    for (let i = 0; i < BankList.length; i++) {
      bankaListesi.push(
        <button
          style={{
            backgroundColor: "transparent",
            backgroundColor: "buttonface",
            borderRadius: 10,
            border: "none",
            margin: "auto",
            alignSelf: "center",
            alignItems: "center",
            width: "100%",
          }}
          onClick={() => setSelectedBank(BankList[i]._id)}
          className="col-lg-12 mb-5"
        >
          <div
            className="card"
            style={{
              backgroundColor: "buttonface",
              border: "none",
              margin: "auto",
            }}
          >
            <div
              className="col-12 d-flex justify-content-center"
              style={{ height: "130px" }}
            >
              <img
                className="bankImage"
                style={{
                  width: "auto",
                  height: "50%",
                  alignSelf: "center",
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex",
                }}
                src={BankList[i].bank_img}
              />
            </div>
            {/* <div className="card-body">
               <h5 className="card-title">{BankList[i].bank_name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {BankList[i].account_name}
                </h6>
                <p className="card-text">{BankList[i].account_number}</p>
                <p className="card-text">{BankList[i].iban}</p>
                <p className="card-text">{BankList[i].swift}</p>
                <p className="card-text">{BankList[i].bank_address}</p>
                <p className="card-text">{BankList[i].bank_country}</p>
                <p className="card-text">{BankList[i].bank_city}</p>
                <p className="card-text">{BankList[i].bank_zip}</p>
                <p className="card-text">{BankList[i].bank_phone}</p>
                <p className="card-text">{BankList[i].bank_email}</p>
                <p className="card-text">{BankList[i].bank_website}</p>
                <p className="card-text">{BankList[i].bank_description}</p>
                <p className="card-text">{BankList[i].bank_status}</p>
                <p className="card-text">{BankList[i].bank_created_at}</p>
                <p className="card-text">{BankList[i].bank_updated_at}</p>
              </div> */}
          </div>
        </button>
      );
    }
    setBankListView(bankaListesi);
  }, [BankList]);

  const styles = getStyles(colors);

  if (!userData || !walletData || !assetList) {
    return <div></div>;
  } else {
    return (
      <>
        <div
          className="container-fluid"
          style={{
            backgroundColor: colors.goldColor,
            height: "100%",
            width: "100%",
            padding: "0",
            overflow: "hidden",
          }}
        >
          <LoginMenu />
          <div
            className="container-fluid"
            style={{
              borderTop: "1px solid rgb(58, 58, 58)",
              background: "#101010",
            }}
          >
            <div className="row">
              <div
                className="col-lg-4 scrollChange scrollHeight"
                style={{
                  borderRight: "1px solid rgb(58, 58, 58)",
                  height: "95vh",
                  padding: "0px 20px 10px 20px",
                  overflowY: "scroll",
                }}
              >
                <div className="row pt-3">
                  <div
                    className="col-lg-4 col-sm-2 accountImage"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    <img
                      src={sessionStorage.getItem("avatar")}
                      style={{ width: "110px", borderRadius: "100%" }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm" style={{ width: "65%" }}>
                    <span
                      className="d-block fs-3 text-light"
                      style={{ fontWeight: "600" }}
                    >
                      {userData.name}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.email}
                    </span>
                    <span className="d-block text-secondary">
                      {userData.phone_number}
                    </span>
                    <span className="d-block text-light">
                      {userData.user_account_number}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle pt-3"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "none", color: "white" }}
                    >
                      <span
                        style={
                          (styles2.menuNavLink,
                          {
                            fontFamily: "averta, Arial",
                            fontWeight: "600",
                            fontSize: "13px",
                          })
                        }
                      >
                        <span id="dashboardFlag">
                          <img
                            src={walletData.assetData.asset_logo}
                            width="25px"
                            height="25px"
                            style={{ borderRadius: "100%" }}
                          />
                        </span>
                        <span style={styles2.menuNavLink}>
                          {walletData.assetData.asset_name ?? ""}{" "}
                          {langObject.account}
                        </span>
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-dark logFix2 bankList"
                      style={{
                        width: "400px",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      {assetListArray}
                    </ul>
                  </div>
                  <h1
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "700",
                      color: "white",
                    }}
                  >
                    {parseFloat(walletData.balance).toFixed(2)}
                    {walletData.assetData.asset_icon ?? ""}
                  </h1>
                </div>
                <div className="row">
                  <div className="col-6">
                    <a
                      href="transfer"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-secondary w-100">
                        {langObject.moneyTransferButton}
                      </div>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="Withdraw"
                      className="text-decoration-none text-light"
                    >
                      <div className="btn btn-outline-secondary w-100">
                        {langObject.withdrawMoneyButton}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="lastOperations">
                  <a
                    href="transaction-page"
                    style={{ textDecoration: "none", fontWeight: "400" }}
                  >
                    <div className="h6 text-secondary my-4">
                      {langObject.lastOperations}
                      <AiOutlineRight style={{ marginTop: "-3px" }} />
                    </div>
                  </a>
                  {lastOperationsArray}
                </div>
              </div>

              <div
                className="col-lg-4 px-4 text-light scrollChange text-center"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                }}
              >
                <h2 className="pt-3" style={{ color: colors.goldColor }}>
                  {langObject.Deposit}
                </h2>
                <p style={{ color: colors.textGray }}>
                  {langObject.depositText}
                </p>

                {bankListView}
              </div>

              <div
                className="col-lg-4 scrollChange"
                style={{
                  height: "95vh",
                  overflowY: "scroll",
                  background: "#101010",
                  color: "white",
                }}
              >
                <div
                  className="col-lg-12 px-12 text-light scrollChange text-center"
                  style={{
                    height: "100vh",
                    overflowY: "scroll",
                    backgroundColor: "#101010",
                  }}
                >
                  <h2 className="pt-3" style={{ color: colors.goldColor }}>
                    {langObject.depositBankDetails}
                  </h2>
                  <p style={{ color: colors.textGray }}>
                    {langObject.depositBankDetailsText}
                  </p>
                  <div className="pageEnd">{bankaBilgileriArray}</div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
};

const styles2 = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

const getStyles = (colors) => ({});

export default UserIndexPage;
