import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import Menu2 from "../Components/Menu2";
import cardDark from "../assets/img/ACKartDark.jpg";
import cardSuccess from "../assets/img/ACKartSuccess.jpg";
import acMobile from "../assets/img/ACMobileCard.png";
import World from "../assets/img/world.png";
import { GiUpgrade, GiTwoCoins } from "react-icons/gi";
import { TbLanguage } from "react-icons/tb";
import { GoGlobe } from "react-icons/go";
import { AiOutlineAntDesign, AiTwotoneApi } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import {
  MdTouchApp,
  MdOutlineFingerprint,
  MdTipsAndUpdates,
} from "react-icons/md";
import acMobile2 from "../assets/img/ACMobile3.png";
import usd from "../assets/img/usd.svg";
import eur from "../assets/img/eur.svg";
import gbp from "../assets/img/gbp.svg";
import { lightColors, darkColors } from "../Colors/Color";
import Footer from "../Components/Footer";
import {
  TickerTape,
  MiniChart,
  EconomicCalendar,
  TechnicalAnalysis,
  CryptocurrencyMarket,
  ForexCrossRates,
  MarketOverview,
} from "react-tradingview-embed";
const IndexPage = () => {
  document.body.style.backgroundColor = "white";
  document.body.style.overflowY = "auto";
  document.body.style.overflowX = "hidden";

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    const script = document.createElement("script");

    document.body.appendChild(script);
    let theme = sessionStorage.getItem("theme");

    let lang = sessionStorage.getItem("lang");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const styles = getStyles(colors);
  return (
    <div
      className="p-0 m-0"
      style={{ backgroundColor: "white", position: "relative" }}
    >
      <div
        className="container p-4 resContainer indexResTop "
        style={{
          minHeight: "100vh",
          backgroundColor: "white",
        }}
      >
        <Menu2 />
        <div
          className="row indexResTop"
          style={{
            justifyContent: "space-between",
            position: "relative",
            height: "92vh",
          }}
        >
          <div className="col-md-3 position-relative resPhoneWorld">
            <div style={{ margin: "-40px 0px -60px 0px" }}>
              <img src={World} width="300px" height={"300px"} />
            </div>
          </div>
          <div
            className="col-md-9 pt-5 text-center indexResTop "
            style={{
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="welcome-intro">
              <h1
                style={{
                  fontSize: "4rem",
                  fontWeight: "700",
                  lineHeight: "1.2",
                }}
                className="indexTextRes"
              >
                {langObject.indexTopH1}
              </h1>
              <h5 className="my-4 indexTextRes2" style={{ fontWeight: "300" }}>
                {langObject.indexTopText}
              </h5>
              <a href="register" className="btn btn-warning btn-lg ">
                {langObject.indexTopButton}
              </a>
            </div>
          </div>

          <div className="col-md-3 position-relative">
            <div className="position-absolute worldRes" style={{ right: "0" }}>
              <img
                src={World}
                width="800px"
                height={"800px"}
                style={{ position: "absolute", right: "-550px", top: "-100px" }}
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center position-relative IndexPadRes">
          <div class="col-12 col-md-10 col-lg-6 w-100">
            <div class="section-heading text-center">
              <h1 className="mb-4 cardHeaderRes">
                {langObject.indexPowerfulH1}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="row position-relative mt-5 IndexPadRes"
          style={{ marginBottom: "5rem" }}
        >
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center">
            <div clasName="card" style={{ width: "18rem" }}>
              <AiOutlineAntDesign style={{ width: "100px", height: "100px" }} />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexFunctionalDesign}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexFunctionalDesignText}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center">
            <div clasName="card" style={{ width: "18rem" }}>
              <MdOutlineFingerprint
                style={{ width: "100px", height: "100px" }}
              />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexSafe}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexSafeText}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center indexResCard">
            <div clasName="card" style={{ width: "18rem" }}>
              <GiUpgrade style={{ width: "100px", height: "100px" }} />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexConstantlyUpdated}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexConstantlyUpdatedText}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center mt-5">
            <div clasName="card" style={{ width: "18rem" }}>
              <GoGlobe style={{ width: "100px", height: "100px" }} />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexGlobalSystem}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexGlobalSystemText}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center mt-5">
            <div clasName="card" style={{ width: "18rem" }}>
              <AiTwotoneApi style={{ width: "100px", height: "100px" }} />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexPowerfulAPI}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexPowerfulAPIText}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex justify-content-center text-center mt-5">
            <div clasName="card" style={{ width: "18rem" }}>
              <TbLanguage style={{ width: "100px", height: "100px" }} />
              <div className="card-body">
                <h5
                  className="card-title mt-3"
                  style={{ color: colors.goldColor }}
                >
                  {langObject.indexMultiLanguageOption}
                </h5>
                <p
                  className="card-text mt-3"
                  style={{ color: colors.textGray }}
                >
                  {langObject.indexMultiLanguageOptionText}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5 mt-5 IndexPadRes">
          <div className="col-md-6 mt-5 d-flex flex-column justify-content-center align-items-start goldRes">
            <h4>
              <GiTwoCoins
                style={{
                  width: "80px",
                  height: "80px",
                  border: "1px solid lightgray",
                  padding: "10px",
                  borderRadius: "10px",
                  color: colors.goldColor,
                  marginRight: "20px",
                }}
              />
              {langObject.indexGoldTrading}
            </h4>
            <h4>
              <BiTransferAlt
                style={{
                  width: "80px",
                  height: "80px",
                  border: "1px solid lightgray",
                  padding: "10px",
                  borderRadius: "10px",
                  color: colors.goldColor,
                  marginRight: "20px",
                }}
              />
              {langObject.indexGoldTransfer}
            </h4>
            <h4>
              <BsFillCreditCard2BackFill
                style={{
                  width: "80px",
                  height: "80px",
                  border: "1px solid lightgray",
                  padding: "10px",
                  borderRadius: "10px",
                  color: colors.goldColor,
                  marginRight: "20px",
                }}
              />
              {langObject.indexSpendingGold}
            </h4>
            <h4>
              <TbTruckDelivery
                style={{
                  width: "80px",
                  height: "80px",
                  border: "1px solid lightgray",
                  padding: "10px",
                  borderRadius: "10px",
                  color: colors.goldColor,
                  marginRight: "20px",
                }}
              />
              {langObject.indexPhysicalGoldDelivery}
            </h4>
          </div>

          <div className="col-md-6 mt-5">
            <img
              src={acMobile}
              className="indexAcImage3"
              width="auto"
              height="450px"
              style={{ borderRadius: "10px" }}
            />
            <img
              src={acMobile2}
              className="indexAcImage"
              width="auto"
              height="450px"
              style={{
                marginLeft: "-50px",
                borderRadius: "10px",
                marginTop: "15px",
                margin: "auto",
              }}
            />
          </div>
        </div>
      </div>
      <Footer clasName="pb-5" />
      <div className="mb-5 tickerTape"> </div>
      {/* Position fixed arası boşluk */}
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          background: "#1e222d",
        }}
      >
        <div className="pb-1 ">
          <TickerTape widgetProps={{ theme: "dark" }}></TickerTape>
        </div>
      </div>
    </div>
  );
};

const getStyles = (colors) => ({
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "white",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  flagDoviz: {
    borderRadius: "100%",
    float: "left",
    width: "18px",
  },
});

export default IndexPage;
