import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import logo from "../assets/img/altinCuzdanLogo.png";
import rightPhoto from "../assets/img/securtiyPhoto.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { lightColors, darkColors } from "../Colors/Color";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

const PinPage = ({ navigation, route }) => {
  const { state } = useLocation();
  const { account_type } = state; // Read values passed on state
  document.body.style.backgroundColor = "white";

  let navigate = useNavigate();

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const [pin, setPin] = React.useState("");
  const setPinData = (event) => {
    setPin(event.target.value);
  };

  const redirectToDashboard = () => {
    navigate("/dashboard");
  };

  async function girisYap() {
    let gonderilecekVeriler = {
      pin: pin,
      reason: "login_sms",
      user_id: sessionStorage.getItem("fake_user_id"),
      account_type: state.account_type,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/3d_secure", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          sessionStorage.setItem(
            "user_id",
            sessionStorage.getItem("fake_user_id")
          );

          sessionStorage.setItem("account_type", state.account_type);
          redirectToDashboard();
        } else {
          toast.error(sonuc.data.alert);
        }
      });
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: colors.backgroundColor }}
    >
      <div className="container  p-0 pt-5" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col-md-8">
            <img
              src={rightPhoto}
              className="d-flex"
              style={styles.securityPhoto}
            />
          </div>
          <div className="col-md-4 mt-5">
            <a href="home" className="d-block" style={{ textAlign: "center" }}>
              <img src={logo} width="auto" height="90px" className="mb-4" />
            </a>
            <h2 className="mt-5">{langObject.pin3DSecure}</h2>
            <p className="fs-6 mt-3 mb-4">{langObject.pinText}</p>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="3D Secure Pin"
                onChange={setPinData}
                maxLength="6"
                style={{ fontSize: "18px" }}
              />
              <label for="floatingInput" style={{ color: "gray" }}>
                {langObject.pin3DSecureText}
              </label>
            </div>

            <div className="form-group">
              <div className="gap-2 text-left">
                <input
                  type="button"
                  className="btn mt-2"
                  onClick={() => girisYap()}
                  value={langObject.registerNext}
                  style={{
                    height: "58px",
                    width: "100%",
                    color: "#F0B90B",
                    backgroundColor: "white",
                    border: "1px solid #F0B90B",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ position: "absolute", bottom: "0" }} />
    </div>
  );
};

const styles = {
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "black",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  securityPhoto: {
    margin: "auto",
    width: "80%",
    height: "auto",
  },
  changeA: {
    textDecoration: "none",
    color: "black",
  },
};

export default PinPage;
