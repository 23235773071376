import React, { useState, useEffect } from "react";
import Menu from "../Components/Menu";
import logo from "../assets/img/altinCuzdanLogo.png";
import rightPhoto from "../assets/img/securtiyPhoto.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { lightColors, darkColors } from "../Colors/Color";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "../Components/Footer";
import axios from "axios";

const ForgetPassword = ({ navigation }) => {
  document.body.style.backgroundColor = "#fff";

  let navigate = useNavigate();

  const redirectToPin = () => {
    navigate("/pin", { state: { account_type: "1" } });
  };

  const [theme, setTheme] = React.useState("");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  const [sifre, setSifre] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [account_type, setAccountType] = React.useState("1");
  const [pinGonderText, setPinGonderText] = React.useState(langObject.sendPin);
  const [div1, setDiv1] = React.useState("");

  function next1() {
    if (
      countryCode === "" ||
      phoneNumber === "" ||
      pin === "" ||
      sifre === ""
    ) {
      toast(langObject.registerBlanks, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }
    setDiv1("none");
  }

  const sifreAyarla = (event) => {
    setSifre(event.target.value);
  };

  const phoneNumberAyarla = (event) => {
    setPhoneNumber(event.target.value);
  };

  const countryCodeAyarla = (event) => {
    setCountryCode(event.target.value);
  };

  const pinAyarla = (event) => {
    setPin(event.target.value);
  };

  const PinGonderTextAyarla = (event) => {
    setPinGonderText(event.target.value);
  };
  const accountTypeAyarla = (event) => {
    setAccountType(event.target.value);
  };

  function guncelleFunction() {
    next1("");
    let gonderilecekVeriler = {
      country_code: countryCode,
      phone_number: phoneNumber,
      pin: pin,
      newPassword: sifre,
      account_type: account_type,
      reason: "forget_password",
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post(
        "http://45.139.201.202:40000/forget_password",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          navigate("/login");
          alert(sonuc.data.alert);
        } else {
          alert(sonuc.data.alert);
        }
      });
  }
  function sendSMS() {
    setPinGonderText(langObject.forgetSendAgain);
    let gonderilecekVeriler = {
      country_code: countryCode,
      phone_number: phoneNumber,
      reason: "forget_password",
      account_type: account_type,
      newPassword: sifre,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("http://45.139.201.202:40000/smsSender", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((sonuc) => {
        if (sonuc.data.message == "user_not_found") {
          alert(langObject.notFoundUser);
        }
        if (sonuc.data.message == "sms_sent") {
          alert(langObject.sendSms);
        }
      });
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: colors.backgroundColor }}
    >
      <div className="container p-0 pt-5" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col-md-8">
            <img
              src={rightPhoto}
              className="d-flex"
              style={styles.securityPhoto}
            />
          </div>

          <div className="col-md-4 mt-5">
            <a href="home" className="d-block" style={{ textAlign: "center" }}>
              <img src={logo} width="auto" height="90px" className="mb-4" />
            </a>
            <h1>
              {" "}
              <FontAwesomeIcon icon="fa-solid fa-house" />
              {langObject.forgetPassword}
            </h1>
            <p className="fs-6 mt-4 mb-4">{langObject.forgetText}</p>{" "}
            <div id="1" style={{ display: div1 }}>
              {" "}
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="countryInput"
                      onChange={countryCodeAyarla}
                      value={countryCode}
                      placeholder="Country Code"
                      autoCapitalize="none"
                      autoCorrect={false}
                    />
                    <label for="floatingInput">{langObject.countryCode}</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={phoneNumberAyarla}
                      value={phoneNumber}
                      id="phoneInput"
                      placeholder="Phone Number"
                      autoCorrect={false}
                      autoCapitalize="none"
                    />
                    <label for="phoneInput">{langObject.Phone}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      style={{ position: "relative" }}
                      id="VerificationInput"
                      onChange={pinAyarla}
                      value={pin}
                      placeholder="Verification Code"
                      autoCapitalize="none"
                      autoCorrect={false}
                    />
                    <label for="verificationInput">
                      {langObject.verificationCode}
                    </label>
                    <input
                      type="button"
                      className="btn mt-2 mb-3"
                      onClick={sendSMS}
                      autoCapitalize="none"
                      autoCorrect={false}
                      value={pinGonderText}
                      style={{
                        width: "auto",
                        color: "#F0B90B",
                        position: "absolute",
                        backgroundColor: "white",
                        border: "1px solid #F0B90B",
                        top: "3px",
                        right: "11px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="password"
                    className="form-control mb-3"
                    onChange={sifreAyarla}
                    value={sifre}
                    placeholder={langObject.forgetNewPassword}
                    style={{ height: "58px" }}
                    autoCapitalize="none"
                    autoCorrect={false}
                  />
                  <h6 style={{ paddingLeft: "10px", fontWeight: "400" }}>
                    {langObject.forgetChoose}
                  </h6>
                  <select
                    className="form-select"
                    value={account_type}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option value="1"> {langObject.individual} </option>
                    <option value="2"> {langObject.corporate} </option>
                  </select>
                </div>
              </div>
              <div className="gap-2" style={{ textAlign: "right" }}>
                <a>
                  <input
                    className="btn mt-2 mb-3"
                    onClick={guncelleFunction}
                    autoCapitalize="none"
                    autoCorrect={false}
                    value={langObject.forgetUpdate}
                    style={{
                      height: "58px",
                      width: "100%",
                      color: "#F0B90B",
                      backgroundColor: "white",
                      border: "1px solid #F0B90B",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ position: "absolute", bottom: "0" }} />
      <ToastContainer />
    </div>
  );
};

const styles = {
  mainDiv: {
    flexDirection: "row",
    backgroundColor: "black",
    alignSelf: "center",
    width: "100%",
    height: "100vh",
  },
  bottomDiv: {
    width: "80%",
    height: "100%",
    alignSelf: "center",
    backgroundColor: "gray",
    margin: "auto",
  },
  securityPhoto: {
    margin: "auto",
    width: "80%",
    height: "auto",
  },
  changeA: {
    textDecoration: "none",
    color: "black",
  },
};

export default ForgetPassword;
