import React, { useState, useEffect } from "react";
import LoginMenu from "../Components/LoginMenu";
import Footer from "../Components/Footer";
import { lightColors, darkColors } from "../Colors/Color";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import englishLogo from "../assets/img/englishLanguage.png";
import turkishLogo from "../assets/img/Flag_of_Turkey.svg.png";
import axios from "axios";
import { AiOutlineRight } from "react-icons/ai";
import Moment from "react-moment";
import "moment/locale/tr";
import LoginPage from "../Screens/Login";
import swapImage from "../assets/img/swap.png";
import "../assets/css/onlyDashboard.css";

import { Navigate, redirect } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";

const UserIndexPage = () => {
  document.body.style.backgroundColor = "#101010";

  const [theme, setTheme] = React.useState("");
  const [user_id, setUserId] = React.useState(null);
  const [frameUrl, setFrmaeUrl] = React.useState(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const [userData, setUserData] = React.useState("");
  const [assetList, setAssetList] = React.useState("");
  const [selectedAssetId, setSelectedAssetId] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [ExchangeAsset1, setExchangeAsset1] = React.useState("TRY");
  const [ExchangeAsset2, setExchangeAsset2] = React.useState("EUR");
  const [walletData, setWalletData] = React.useState("");
  const [ExchangeData1, setExchangeData1] = React.useState("");
  const [ExchangeData2, setExchangeData2] = React.useState("");

  const [ModalVisible, setModalVisible] = React.useState(false);

  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const [lastOperations, setLastOperations] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [asset, setAsset] = React.useState("6367c3e400ac884500039085");
  const [asset2, setAsset2] = React.useState("6367c3af00ac88450003907f");

  const [exchangeMiktar, setExchangeMiktar] = React.useState(0);
  const [account_type, setAccountType] = React.useState(null);
  const [isReady, setisReady] = React.useState(false);
  const [isRendered, setisRendered] = React.useState(false);
  async function getData() {
    let result = sessionStorage.getItem("user_id");

    if (result) {
      setUserId(result);
    }
  }

  const notify = () => toast("Wow so easy!");

  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    setAccountType(sessionStorage.getItem("account_type"));

    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    getWallet("TRY");
    getWalletForExchange1("TRY");
    getWalletForExchange2("EUR");
    getUserInfo();
    getAssetList();
    getLastOperations();
  }, [user_id, account_type]);

  useEffect(() => {
    if (
      !isRendered &&
      userData &&
      walletData &&
      assetList &&
      ExchangeData1 &&
      ExchangeData2
    ) {
      !isReady && setisReady(true);
      setisRendered(true);
    }
  }, [userData, walletData, assetList, ExchangeData1, ExchangeData2]);

  function redirect(path) {
    window.location.href = path;
  }

  async function getUserInfo() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      user_id: sessionStorage.getItem("user_id"),
      account_type: sessionStorage.getItem("account_type"),
      lang: sessionStorage.getItem("lang"),
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          window.location.href = "/login";
        }
      });
  }

  function getAssetList() {
    let gonderilecekVeriler = {
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"),
    };

    axios
      .post("https://acdigitalapi.com/assetList", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function changeExchangeAsset1(symbol, id) {
    getWalletForExchange1(symbol);
    setExchangeAsset1(symbol);
    setAmount("");
    setExchangeMiktar(0);
    setAsset(id);
  }

  function changeExchangeAsset2(symbol, id) {
    getWalletForExchange2(symbol);
    setExchangeAsset2(symbol);
    setAsset2(id);
    setExchangeMiktar(0);
    setAmount("");
  }

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"),
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          if (sonuc.data.data.balance.toString().includes(".")) {
            let explode = sonuc.data.data.balance.split(".");
            let explode2 = explode[1].substring(0, 2);

            let tryBakiyem2 = explode[0] + "." + explode2;

            sonuc.data.data.balance = tryBakiyem2;
          } else {
          }

          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  const createPdfDocument = (name, phone, Mail) => {
    let doc = Mail.split("@")[0] + Math.random() * 100;
    let gonderilecekVeriler = {
      isim: name,
      telefon: phone,
      adres: "",
      eposta: Mail,
      dosya: doc,
      dil: "EN",
      lang: sessionStorage.getItem("lang"),
    };
    axios
      .get("https://www.altincuzdan.com/HTML/pdfjs/web/ajax.php", {
        params: gonderilecekVeriler,
      })
      .then((sonuc) => {
        if (sonuc.status == 200) {
          setFrmaeUrl(
            "https://www.altincuzdan.com/HTML/pdfjs/web/viewer.html?file=https://www.altincuzdan.com/HTML/pdfjs/sozlesmeler/" +
              doc +
              ".pdf"
          );
          window.scrollTo(0, 0);
          document.body.style.overflow = "hidden";
          setModalVisible(true);
        }
      });
  };

  function getWalletForExchange1(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"),
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData1(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getWalletForExchange2(asset) {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      account: asset,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"),
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeData2(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function getLastOperations() {
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      limit: 5,
      account_type: sessionStorage.getItem("account_type"),
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      lang: sessionStorage.getItem("lang"),
    };

    axios
      .post(
        "https://acdigitalapi.com/getmoneyoperations",
        gonderilecekVeriler,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setLastOperations(sonuc.data.message);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }
  let assetListArray2 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray2.push(
          <li
            onClick={() =>
              changeExchangeAsset1(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let assetListArray3 = [];
  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray3.push(
          <li
            onClick={() =>
              changeExchangeAsset2(assetList[i].asset_symbol, assetList[i]._id)
            }
          >
            <a className="dropdown-item">
              {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
            </a>
          </li>
        );
      }
    }
  }

  let lastOperationsArray = [];

  if (lastOperations) {
    for (let i = 0; i < lastOperations.length; i++) {
      lastOperationsArray.push(
        <div
          className="text-light"
          style={{ borderBottom: "1px solid rgb(58, 58, 58)" }}
        >
          <p
            className="text-secondary"
            style={{ fontSize: "14px", marginBottom: "4px", marginTop: "10px" }}
          >
            <Moment format="DD/MM/YYYY">{lastOperations[i].createdAt}</Moment>
          </p>
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <div className="col-lg-2" style={{ width: "auto" }}>
              <img
                src={lastOperations[i].image}
                style={{
                  width: "70px",
                  height: "70px",
                  paddingRight: "5px",
                }}
              ></img>
            </div>
            <div className="col-lg-7" style={{ width: "auto" }}>
              <div className="row flex-column">
                <div
                  className="col text-light fs-5"
                  style={{ fontWeight: "600" }}
                >
                  {lastOperations[i].title}
                </div>
                <div className="col text-secondary">
                  {lastOperations[i].explanation}
                </div>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{
                textAlign: "right",
                width: "auto",
                position: "absolute",
                right: "0",
              }}
            >
              <div className="row flex-column">
                <div className="col">
                  {parseFloat(lastOperations[i].amount).toFixed(2)}{" "}
                  {lastOperations[i].asset_icon}
                </div>
                <div className="col">
                  <p
                    className="text-secondary"
                    style={{ fontSize: "14px", marginBottom: "0" }}
                  >
                    <Moment format="HH:mm">
                      {lastOperations[i].createdAt}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  async function getExchange(miktar) {
    if (miktar == 0) {
      setExchangeMiktar(0);
      return;
    }
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: miktar,
      from: asset,
      to: asset2,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      account_type: sessionStorage.getItem("account_type"),
      lang: sessionStorage.getItem("lang"),
    };
    let token = sessionStorage.getItem("token");

    axios
      .post("https://acdigitalapi.com/ExchangePanel", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setExchangeMiktar(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }

  function changeFunction(text) {
    if (isNaN(text)) {
      return;
    }

    if (text < 0) {
      text = 0;
      return;
    }

    setAmount(text);
    getExchange(text);
  }

  function takasYap() {
    setButtonDisabled(true);
    let gonderilecekVeriler = {
      user_id: sessionStorage.getItem("user_id"),
      amount: amount,
      from: asset,
      to: asset2,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
      account_type: sessionStorage.getItem("account_type"),
      lang: sessionStorage.getItem("lang"),
    };
    let token = sessionStorage.getItem("token");

    axios
      .post("https://acdigitalapi.com/exchange", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
      .then((sonuc) => {
        setButtonDisabled(false);
        if (sonuc.data.status == "success") {
          getWallet(selectedAssetSymbol);
          getWalletForExchange1(ExchangeAsset1);
          getWalletForExchange2(ExchangeAsset2);
          setAmount(0);
          setExchangeMiktar(0);
          getLastOperations();
          toast(sonuc.data.alert, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast(sonuc.data.alert, {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }

  function acceptFunction() {
    setIsVisible(false);
    takasYap();
  }

  function modalAc() {
    if (ExchangeAsset1 == ExchangeAsset2) {
      toast(langObject.cantSame, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (
      amount == 0 ||
      amount == "" ||
      amount == null ||
      amount == undefined ||
      amount < 0
    ) {
      toast(langObject.validAmount, {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    Swal.fire({
      title: langObject.swalTitle,
      text: langObject.swalText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.goldColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (ExchangeAsset1 == "Altın" || ExchangeAsset2 == "Altın") {
          //PDF AÇMA KISMI BURASI

          let name = "";
          let phone_number = "";
          let email = "";

          if (account_type == 1) {
            name = userData.name;
            phone_number = userData.phone_number;
            email = userData.email;
          } else {
            name = userData.company_name;
            phone_number = userData.phone_number;
            email = userData.email;
          }
          createPdfDocument(name, phone_number, email);
          setIsVisible(true);
        } else {
          takasYap();
        }
      }
    });
  }

  const styles = getStyles(colors);
  return isReady == true ? (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundColor: colors.goldColor,
          height: "100%",
          width: "100%",
          padding: "0",
          overflow: "hidden",
        }}
      >
        <LoginMenu />
        <div
          className="container-fluid"
          style={{
            borderTop: "1px solid rgb(58, 58, 58)",
            background: "#101010",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              zIndex: 5,
              display: isVisible ? "block" : "none",
              backgroundColor: "#fff",
            }}
          >
            <iframe
              type="application/pdf"
              height="90%"
              width="100%"
              onload="scroll(0,0);"
              src={frameUrl}
            ></iframe>
            <div
              style={{
                height: "11%",
                display: "flex",
                justifyContent: "flex-end",
                background: "#3f3f3f",
                marginInline: 10,
                margin: "auto",
              }}
            >
              <button
                style={{
                  margin: "auto",
                  width: "40%",
                  backgroundColor: colors.goldColor,
                }}
                className="btn"
                onClick={() => {
                  acceptFunction();
                  document.body.style.overflow = "auto";
                }}
              >
                {langObject.acceptButton}
              </button>
              <button
                style={{ margin: "auto", width: "40%" }}
                className="btn btn-danger"
                onClick={() => {
                  setIsVisible(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {langObject.declineButton}
              </button>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-4 scrollChange scrollHeight"
              style={{
                borderRight: "1px solid rgb(58, 58, 58)",
                height: "95vh",
                overflowY: "scroll",
                padding: "0px 20px 10px 20px",
              }}
            >
              <div className="row pt-3">
                <div
                  className="col-lg-4 col-sm-2 accountImage"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30%",
                  }}
                >
                  <img
                    src={sessionStorage.getItem("avatar")}
                    style={{ width: "110px", borderRadius: "100%" }}
                  />
                </div>
                <div className="col-lg-8 col-sm" style={{ width: "65%" }}>
                  <span
                    className="d-block fs-3 text-light"
                    style={{ fontWeight: "600" }}
                  >
                    {userData.name}
                  </span>
                  <span className="d-block text-secondary">
                    {userData.email}
                  </span>
                  <span className="d-block text-secondary">
                    {userData.phone_number}
                  </span>
                  <span className="d-block text-light">
                    {userData.user_account_number}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle pt-3"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ border: "none", color: "white" }}
                  >
                    <span
                      style={
                        (styles2.menuNavLink,
                        {
                          fontFamily: "averta, Arial",
                          fontWeight: "600",
                          fontSize: "13px",
                        })
                      }
                    >
                      <span id="dashboardFlag">
                        <img
                          src={walletData.assetData.asset_logo}
                          width="25px"
                          height="25px"
                          style={{ borderRadius: "100%" }}
                        />
                      </span>
                      <span style={styles2.menuNavLink}>
                        {walletData.assetData.asset_name ?? ""}{" "}
                        {langObject.account}
                      </span>
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-dark logFix2 bankList"
                    style={{
                      width: "400px",
                      paddingTop: "0",
                      marginTop: "0",
                    }}
                  >
                    {assetListArray}
                  </ul>
                </div>
                <h1
                  style={{
                    paddingLeft: "10px",
                    fontWeight: "700",
                    color: "white",
                  }}
                >
                  {parseFloat(walletData.balance).toFixed(2)}
                  {walletData.assetData.asset_icon ?? ""}
                </h1>
              </div>
              <div className="row">
                <div className="col-6">
                  <a
                    href="transfer"
                    className="text-decoration-none text-light"
                  >
                    <div className="btn btn-secondary w-100">
                      {langObject.moneyTransferButton}
                    </div>
                  </a>
                </div>
                <div className="col-6">
                  <a
                    href="Withdraw"
                    className="text-decoration-none text-light"
                  >
                    <div className="btn btn-outline-secondary w-100">
                      {langObject.withdrawMoneyButton}
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <a
                  href="transaction-page"
                  style={{ textDecoration: "none", fontWeight: "400" }}
                >
                  <div className="h6 text-secondary my-4">
                    {langObject.lastOperations}
                    <AiOutlineRight style={{ marginTop: "-3px" }} />
                  </div>
                </a>
                {lastOperationsArray}
              </div>
            </div>

            <div
              className="col-lg-4 px-4 text-light scrollChange text-center scrollHeight"
              style={{
                height: "95vh",
                overflowY: "scroll",
              }}
            >
              <h2 className="pt-3" style={{ color: colors.goldColor }}>
                {langObject.exchange}
              </h2>
              <p style={{ color: colors.textGray }}>
                {langObject.exchangeText}
              </p>

              <div class="input-group mb-3">
                <span
                  class="input-group-text"
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderRight: "0",
                  }}
                >
                  {langObject.amount}
                </span>
                <input
                  type="text"
                  class="form-control"
                  value={amount}
                  aria-label="Amount (to the nearest dollar)"
                  onChange={(e) => changeFunction(e.target.value)}
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderRight: "0",
                    borderLeft: "0",
                  }}
                />
                <span
                  class="input-group-text"
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderLeft: "0",
                  }}
                >
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        border: "none",
                        color: "black",
                        padding: "0.5rem",
                      }}
                    >
                      <span
                        style={
                          (styles.menuNavLink,
                          {
                            fontFamily: "averta, Arial",
                            fontWeight: "600",
                            fontSize: "13px",
                          })
                        }
                      >
                        <span style={styles.menuNavLink}>
                          {ExchangeAsset1}

                          {/* <ChevronDownSharp
                        color={"#ffffff"}
                        height="15px"
                        width="15px"
                      /> */}
                        </span>
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-dark logFix3"
                      style={{
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      {assetListArray2}
                    </ul>
                  </div>
                </span>
              </div>
              <p style={{ textAlign: "right" }}>
                {langObject.balance}:
                {parseFloat(ExchangeData1.balance).toFixed(2)}
                {ExchangeData1.assetData.asset_icon ?? ""}
              </p>
              <div class="input-group mb-3">
                <span
                  class="input-group-text"
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderRight: "0",
                  }}
                >
                  {langObject.amount}
                </span>
                <input
                  type="text"
                  class="form-control"
                  value={exchangeMiktar.toFixed(2)}
                  aria-label="Amount (to the nearest dollar)"
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderRight: "0",
                    borderLeft: "0",
                  }}
                />
                <span
                  class="input-group-text"
                  style={{
                    border: "2px solid " + colors.goldColor,
                    borderColor: colors.goldColor,
                    borderLeft: "0",
                  }}
                >
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        border: "none",
                        color: "black",
                        padding: "0.5rem",
                      }}
                    >
                      <span
                        style={
                          (styles.menuNavLink,
                          {
                            fontFamily: "averta, Arial",
                            fontWeight: "600",
                            fontSize: "13px",
                          })
                        }
                      >
                        <span style={styles.menuNavLink}>
                          {ExchangeAsset2}
                          {/* <ChevronDownSharp
                        color={"#ffffff"}
                        height="15px"
                        width="15px"
                      /> */}
                        </span>
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-dark logFix3"
                      style={{
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      {assetListArray3}
                    </ul>
                  </div>
                </span>
              </div>
              <p style={{ textAlign: "right" }}>
                {langObject.balance}:{" "}
                {parseFloat(ExchangeData2.balance).toFixed(2)}
                {ExchangeData2.assetData.asset_icon ?? ""}
              </p>
              <button
                className="btn btn-block btn-warning w-100 mb-3"
                onClick={() => modalAc()}
                disabled={buttonDisabled}
              >
                {langObject.exchange}
              </button>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  width: "auto",
                  padding: "0",
                }}
              ></div>
            </div>
            <div
              className="col-lg-4 scrollChange"
              style={{
                height: "95vh",
                overflowY: "scroll",
                background: "#101010",
                color: "white",
              }}
            ></div>
          </div>
          <Footer />
        </div>
      </div>

      <ToastContainer />
    </>
  ) : (
    <div className="container"></div>
  );
};

const styles2 = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "white",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

const getStyles = (colors) => ({});

export default UserIndexPage;
