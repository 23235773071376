import React, { useEffect } from "react";
import "../main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  NavLink,
  Row,
} from "react-bootstrap";
import logo from "../assets/img/altinCuzdanLogo.png";
import acIcon from "../assets/img/acIcon.png";
import { FiLogIn } from "react-icons/fi";
import { FaUserTie } from "react-icons/fa";
import { ImUser } from "react-icons/im";
import { AiOutlineUserAdd } from "react-icons/ai";
import { lightColors, darkColors } from "../Colors/Color";
import axios from "axios";

const Menu = () => {
  const [div1, setDiv1] = React.useState("none"); //Account open
  const [div2, setDiv2] = React.useState("none"); //Wallet open
  const [user_id, setUserId] = React.useState("");
  const [userData, setUserData] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [walletData, setWalletData] = React.useState("");
  const [selectedAssetSymbol, setSelectedAssetSymbol] = React.useState("TRY");
  const [assetList, setAssetList] = React.useState("");
  const [accountType, setaccountType] = React.useState("");

  async function getData() {
    let result = await sessionStorage.getItem("user_id");
    let result2 = await sessionStorage.getItem("account_type");
    if (result) {
      setUserId(result);
      setaccountType(result2);
    }
  }
  let lang = sessionStorage.getItem("lang");

  let langObject = require("../language/" + lang + ".json");

  useEffect(() => {
    let theme = sessionStorage.getItem("theme");

    getData();

    if (!theme) {
      setTheme("light");
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "dark") {
      setTheme("dark");
    }

    if (theme === "light") {
      setTheme("light");
    }
  }, []);
  useEffect(() => {
    getWallet("TRY");
    getUserInfo();
    getAssetList();
  }, [user_id]);
  async function getUserInfo() {
    let gonderilecekVeriler = {
      user_id: user_id,
      account_type: accountType,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    await axios
      .post("https://acdigitalapi.com/profile", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setUserData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  async function changeLang(lang) {
    sessionStorage.setItem("lang", lang);
    window.location.reload(false);
  }

  function getAssetList() {
    axios
      .post("https://acdigitalapi.com/assetList", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setAssetList(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }

  function changeSelectedAsset(symbol) {
    getWallet(symbol);
    setSelectedAssetSymbol(symbol);
  }

  function getWallet(asset) {
    let gonderilecekVeriler = {
      user_id: user_id,
      account: asset,
      api_key: "yup13kma513myxn4ff9og",
      api_secret: "cdubuccld7kn9zc2vdihfc",
    };

    axios
      .post("https://acdigitalapi.com/GetAsset", gonderilecekVeriler, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((sonuc) => {
        if (sonuc.data.status == "success") {
          setWalletData(sonuc.data.data);
        } else {
          //alert(sonuc.data.alert);
        }
      });
  }
  let assetListArray = [];

  if (assetList) {
    if (assetList.length > 0) {
      for (let i = 0; i < assetList.length; i++) {
        assetListArray.push(
          <li onClick={() => changeSelectedAsset(assetList[i].asset_symbol)}>
            <a className="dropdown-item" style={{ cursor: "pointer" }}>
              <div className="row">
                <div className="col-3 haric">
                  <img
                    src={assetList[i].asset_logo}
                    width="60px"
                    height="60px"
                    style={{ borderRadius: "50%" }}
                    alt="logo"
                  ></img>
                </div>
                <div className="col-6 justify-content-start haric">
                  {assetList[i].asset_symbol} • {assetList[i].asset_name ?? ""}
                </div>
                <div className="col-3 justify-content-end haric"></div>
              </div>
            </a>
          </li>
        );
      }
    }
  }

  function logoutFunction() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_id");
    window.location.href = "/";
  }

  function accountOpen() {
    if (div1 === "block") {
      setDiv1("none");
    } else {
      setDiv1("block");
    }
  }

  function walletOpen() {
    if (div1 === "block") {
      setDiv1("none");
    } else {
      setDiv1("block");
    }
  }

  function changeTheme() {
    let theme = sessionStorage.getItem("theme");

    if (theme === "dark") {
      sessionStorage.setItem("theme", "light");
    }

    if (theme === "light") {
      sessionStorage.setItem("theme", "dark");
    }
  }

  let colors = "";

  if (theme === "light") {
    colors = lightColors;
  } else {
    colors = darkColors;
  }
  return (
    <div>
      <div className="navbar navbar-expand-lg logFix resMenu ">
        <div
          className="container-fluid d-inline"
          style={{ position: "relative", marginBottom: "10px" }}
        >
          <div
            className="row changeRow rowPadRes"
            style={{
              alignItems: "center",
              width: "auto",
            }}
          >
            <div className="col-lg-2" id="LogoDiv">
              <a href="home">
                <img src={logo} width="auto" height="70px" className="acLogo" />
                <img
                  src={acIcon}
                  width="auto"
                  height="70px"
                  className="acIcon"
                />
              </a>
            </div>
            <div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                className="col-lg-5 fontSize2 menuWidth2 menuWidth3"
                style={{ width: "50%" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <a
                      href="services"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {langObject.services}
                    </a>
                  </li>


                  <li className="nav-item">
                    <a
                      href="Contact"
                      className="nav-link"
                      style={styles.menuNavLink}
                      target="_blank"
                    >
                      {langObject.contact}
                    </a>
                  </li>

                  <select
                    className="custom-select"
                    onChange={(event) => changeLang(event.target.value)}
                    style={{ borderWidth: 0, padding: 10 }}
                    value={lang}
                  >
                    <option value="tr"> Türkçe </option>
                    <option value="en"> English </option>
                    <option value="ru"> Русский </option>
                    <option value="de"> Deutsch </option>
                    <option value="es"> Español </option>
                    <option value="fr"> Français </option>
                    <option value="it"> Italiano </option>
                  </select>
                </ul>
              </div>
              <div
                className="col-lg-5 fontSize2 menuRightWidth2"
                style={{ width: "60%", borderLeft: "none!important" }}
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      href="login"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      <ImUser style={{ width: "40px", height: "40px" }} />
                      {langObject.individual}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="corporateLogin"
                      className="nav-link"
                      style={styles.menuNavLink}
                    >
                      {/* <AiOutlineUserAdd style={styles.iconSizing} /> */}
                      <FaUserTie
                        style={{ width: "40px", height: "40px" }}
                      />{" "}
                      {langObject.corporate}
                    </a>
                  </li>
                </ul>
              </div>

              <div
                className="col-lg-2"
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div
                  className="row"
                  style={{
                    height: "70px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Navbar.Collapse
                    id="responsive-navbar-nav"
                    style={{ width: "100%" }}
                  >
                    <Nav style={{ textAlign: "right" }}>
                      <NavLink
                        href="login"
                        className="btn btn-sm p-3 loginButton"
                        style={{
                          color: "#000",
                          background: "#f7fafd",
                          borderRadius: "10px",
                        }}
                      >
                        <ImUser style={{ width: "40px", height: "40px" }} />
                        <span style={{ fontSize: "18px", fontWeight: "400" }}>
                          {langObject.individual}
                        </span>
                      </NavLink>
                    </Nav>

                    <Nav
                      style={{
                        paddingLeft: "30px",
                        textAlign: "right",
                        zIndex: "99",
                      }}
                    >
                      <NavLink
                        href="corporateLogin"
                        className="btn btn-sm loginButton p-3"
                        style={{
                          color: "#000",
                          background: "#f7fafd",
                          borderRadius: "10px",
                        }}
                      >
                        <FaUserTie style={{ width: "40px", height: "40px" }} />{" "}
                        <span style={{ fontSize: "18px", fontWeight: "400" }}>
                          {langObject.corporate}
                        </span>
                      </NavLink>
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  menuDiv: {
    color: "",
    fontWeight: "500",
    fontFamily: "averta,Arial;",
    padding: "0",
    margin: "0",
  },
  menuNavLink: {
    color: "black",
  },
  iconSizing: {
    width: "auto",
    height: "30px",
    paddingRight: "20px",
    opacity: "0.7",
  },
};

export default Menu;
